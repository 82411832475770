import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { Button, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { Image } from 'mui-image';
import { useLocation } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
// import c1 from '../../../assets/tourism/bearland/1.jpg';
// import c2 from '../../../assets/tourism/solymar/9.jpg';
// import c3 from '../../../assets/tourism/dreamersValley/1.jpg';
import c1 from '../../../assets/carousel/1.png';
import c2 from '../../../assets/carousel/2.png';
import c3 from '../../../assets/carousel/3.png';
import misc from 'functions/misc';
import filters from 'functions/filters';
export const BannerCarousel = () => {
  const { pathname } = useLocation();
  if (pathname === '/')
    return (
      <Box
        sx={{
          backgroundColor: 'background.paper',
          width: '100%',
          boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            '& .carousel-status': { fontSize: '1.2rem', pr: '12px' },
            '& .carouselImageContainer': { maxHeight: '400px' },
            '& .carouselImage': { height: '400px' },
            '& .legend': {
              borderRadius: '0px !important',
              opacity: '1 !important',
              // ml: '-50% !important',
              width: '100% !important',
              bottom: '0px !important',
              backgroundColor: 'hsla(0,0%,4%,.5) !important',
              // height: '25px',
              p: '1rem !important',
              fontSize: '16px !important',
              textAlign: 'left !important',
              zIndex: '100 !important',
            },

            maxHeight: '400px',
            // width: '100rem',
            alignSelf: 'center',
          }}
        >
          {/* <Box sx={{ overflow: 'hidden' }}>
            <Box
              sx={{
                position: 'absolute',

                zIndex: 5,

                // backgroundColor: 'rgba(255,255,255,0.5)',
                // borderLeft: '1vw solid transparent',
                borderRight: '100vw solid transparent',
                borderTop: '8vw solid rgba(255, 229, 138, 0.5)',
                height: '0',
                maxWidth: '100vw !important',
                // borderTopRightRadius: '5px',
                // borderBottomRightRadius: '5px',
              }}
            ></Box>
            <Box
              sx={{
                position: 'absolute',

                zIndex: 5,

                // backgroundColor: 'rgba(255,255,255,0.5)',
                borderLeft: '50vw solid transparent',
                borderRight: '50vw solid transparent',
                borderTop: '4vw solid rgba(255, 229, 138, 1)',

                height: '0',

                // borderTopRightRadius: '5px',
                // borderBottomRightRadius: '5px',
              }}
            ></Box>
            <Box
              sx={{
                position: 'absolute',

                zIndex: 5,

                // backgroundColor: 'rgba(255,255,255,0.5)',
                // borderLeft: '1vw solid transparent',
                borderLeft: '100vw solid transparent',
                borderTop: '8vw solid rgba(255, 229, 138, 0.5)',
                height: '0',

                // borderTopRightRadius: '5px',
                // borderBottomRightRadius: '5px',
              }}
            ></Box>
          </Box> */}

          <Carousel
            interval={5000}
            infiniteLoop
            autoPlay
            showThumbs={false}
            showIndicators={false}
            showStatus={false}
            swipeable={true}
            renderArrowPrev={(onClickHandler, hasPrev, label) =>
              hasPrev && (
                <Button
                  disableRipple
                  sx={{
                    position: 'absolute',
                    zIndex: 2,
                    top: 'calc(50% - 15px)',
                    width: 30,
                    height: 30,
                    left: 15,
                    '& :hover': { backgroundColor: 'none' },
                  }}
                  onClick={onClickHandler}
                >
                  <KeyboardArrowLeft
                    sx={{
                      fontSize: '7rem',
                      color: 'secondary.main',
                      // backgroundColor: 'primary.main',
                      // border: '5px solid transparent',
                      opacity: '0.50',
                    }}
                  />
                </Button>
              )
            }
            renderArrowNext={(onClickHandler, hasPrev, label) =>
              hasPrev && (
                <Button
                  disableRipple
                  sx={{
                    position: 'absolute',
                    zIndex: 2,
                    top: 'calc(50% - 15px)',
                    width: 30,
                    height: 30,
                    right: 15,
                    '& :hover': { backgroundColor: 'none' },
                  }}
                  onClick={onClickHandler}
                >
                  <KeyboardArrowRight
                    sx={{
                      fontSize: '7rem',
                      color: 'secondary.main',
                      // backgroundColor: 'primary.main',
                      // border: '5px solid transparent',
                      opacity: '0.50',
                    }}
                  />
                </Button>
              )
            }
            // renderArrowNext={(onClickHandler, hasNext, label) =>
            //   hasNext && (
            //     <button
            //       type="button"
            //       onClick={onClickHandler}
            //       title={label}
            //       style={{ ...arrowStyles, right: 15 }}
            //     >
            //       +
            //     </button>
            //   )
            // }
            // renderArrowNext={() => {
            //   return (
            //     <Button>
            //
            //     </Button>
            //   );
            // }}
            // renderArrowPrev={() => {
            //   return <KeyboardArrowRight />;
            // }}
          >
            <Box sx={{}} className="carouselImageContainer">
              <Image
                src={c1}
                duration={1}
                fit="scale-down"
                height='400px'
                className="carouselImage"

              />
              <Typography className="legend" sx={{ width: '100rem' }}>
                Municipal Mayor Atty. Virgilio T. Teruel
              </Typography>
            </Box>

            <Box sx={{}} className="carouselImageContainer">
              <Image
                src={c2}
                duration={1}
                fit="scale-down"
                height='400px'
                className="carouselImage"
              />
              <Typography className="legend" sx={{ width: '100rem' }}>
                Tigbauan LGU
              </Typography>
            </Box>
            <Box sx={{}} className="carouselImageContainer">
              <Image
                src={c3}
                duration={1}
                fit="scale-down"
                height='400px'
                className="carouselImage"
              />
              <Typography className="legend" sx={{ width: '100rem' }}>
                Saludan Festival
              </Typography>
            </Box>
          </Carousel>
        </Box>
      </Box>
    );
  else return null;
};
