import * as React from 'react';
import Link, { LinkProps } from '@mui/material/Link';
import { ListItemProps } from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Paper } from '@mui/material';
import { useDispatch } from 'react-redux';
import { apiStoreNav } from 'actions/actionCreator';

interface ListItemLinkProps extends ListItemProps {
  to: string;
  open?: boolean;
}

const breadcrumbNameMap: { [key: string]: string } = {
  '/home/about-tigbauan': 'About Tigbauan',
  '/home/mission-vision': 'Mission Vision',

  '/home': 'Home',
  '/mayors-page': 'Mayors Page',
  '/mayors-page/mayors-bio': 'Mayors Bio',
  '/mayors-page/office-order': 'Office Order',
  '/mayors-page/executive-order': 'Executive Order',
  '/mayors-page/services': 'Services',
  '/mayors-page/announcements': 'Announcements',
  '/mayors-page/activities': 'Activities',
  '/mayors-page/organizational-chart': 'Organizational Chart',
  '/legislative': 'Legislative',
  '/legislative/vice-mayors-bio': 'Vice Mayors Bio',
  '/legislative/services': 'Services',
  '/legislative/ordinances': 'Ordinances',
  '/legislative/resolutions': 'Resolutions',
  '/legislative/announcements': 'Announcements',
  '/legislative/organizational-chart': 'Organizational Chart',
  '/local-offices': 'Local Offices',
  '/local-offices/accounting-office': 'Accounting Office',
  '/local-offices/agriculture-office': 'Agriculture Office',
  '/local-offices/assessors-office': 'Assessors Office',
  '/local-offices/budget-office': 'Budget Office',
  '/local-offices/engineering-office': 'Engineering Office',
  '/local-offices/environmental-and-natural-resources-office':
    'Environment And Natural Resources Office',
  '/local-offices/health-office': 'Health Office',
  '/local-offices/local-civil-registrar-office': 'Local Civil Registrar Office',
  '/local-offices/market-and-slaughter-house': 'Market And Slaughter House',
  '/local-offices/municipal-disaster-risk-reduction-management-office':
    'Municipal Disaster Risk Reduction Office',
  '/local-offices/planning-office': 'Planning Office',
  '/local-offices/social-welfare-and-development-office':
    'Social Welfare And Development Office',
  '/local-offices/treasurers-office': 'Treasurers Office',
  '/national-offices': 'National Offices',
  '/national-offices/bureau-of-fire-protection': 'Bureau of Fire Protection',
  '/national-offices/comelec': 'Comelec',
  '/national-offices/department-of-agrarian-reform':
    'Department of Agrarian Reform',
  '/national-offices/department-of-interior-and-local-government':
    'Department of Interior and Local Government',
  '/national-offices/population-commmission': 'Population Commmission',
  '/national-offices/philippine-national-police': 'Philippine National Police',
  '/national-offices/post-office': 'Post Offices',
  '/tourism': 'Tourism',
  '/tourism/tigbauan-festival': 'Tigbauan Festival',
  '/tourism/tigbauan-products': 'Tigbauan Products',
  '/tourism/tourists-spots': 'Tourists Spots',
  '/bids-and-awards': 'Bids and Awards',
  '/contact-us': 'Contact Us',
  '/posts': 'Posts',
  '/jobs': 'Jobs',
  '/announcements': 'Announcements',
  '/post': 'Post',
  '/announcement': 'Announcement',
  '/about': 'About',
  '/search': 'Search',
  '/downloads': 'Downloads',
  '/transparency-seal': 'Transparency Seal',
};

interface LinkRouterProps extends LinkProps {
  to: string;
  replace?: boolean;
}

const LinkRouter = (props: LinkRouterProps) => (
  <Link {...props} component={RouterLink as any} />
);

const Page = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const pathnames = pathname.split('/').filter(x => x);
  //console.log(pathnames[pathnames.length - 1]);
  dispatch(apiStoreNav(pathnames[pathnames.length - 1]));
  // React.useEffect(() => {
  //   dispatch(apiStoreNav(pathnames));
  // }, [pathname]);
  return (
    <Breadcrumbs aria-label="breadcrumb">
      {/* {pathnames.includes('home') ? (
        <LinkRouter underline="hover" color="inherit" to="/">
          Home
        </LinkRouter>
      ) : null} */}

      {pathnames.map((value, index) => {
        const last = index === pathnames.length - 1;
        const to = `/${pathnames.slice(0, index + 1).join('/')}`;
        //console.log(to);
        // if (last) {
        //   dispatch(apiStoreNav(to.replace('/', '')));
        // }
        return last ? (
          <Typography color="text.primary" key={to}>
            {breadcrumbNameMap[to]}
          </Typography>
        ) : (
          <LinkRouter underline="hover" color="inherit" to={to} key={to}>
            {breadcrumbNameMap[to]}
          </LinkRouter>
        );
      })}
    </Breadcrumbs>
  );
};

export default function RouterBreadcrumbs() {
  const { pathname } = useLocation();
  return pathname === '' || pathname === '/' ? null : (
    <Paper sx={{ p: '7.5px', mb: '7.5px', display: 'flex' }} variant="outlined">
      <Typography sx={{ pr: '7.5px' }}>YOU ARE HERE:</Typography>
      <Page />
    </Paper>
  );
}
