import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import Box from '@mui/material/Box';
import { lazyLoad } from 'utils/loadable';
import { Divider, Paper, Typography } from '@mui/material';

export const BidsAndAwards = lazyLoad(
  () => import('./BidsAndAwards'),
  module => module.BidsAndAwardsContent,
);

export function BidsAndAwardsContent(props: any) {
  return (
    <>
      <Helmet>
        <title>Bids And Awards</title>
        <meta name="description" content="Bids And Awards" />
      </Helmet>
      <Box sx={{ p: '15px' }}>
        <Typography variant="h4" sx={{ color: 'background.paper' }}>Bids And Awards</Typography>
        <Divider />
      </Box>
      <Typography variant="h4" sx={{ color: 'background.paper' }}>BAC Information</Typography>
      <Divider />
      <Paper variant="outlined" sx={{ my: '15px', p: '15px' }}>
        <Typography sx={{ textAlign: 'justify' }}>
          Bids and Awards Committee (BAC)
        </Typography>
        <Typography sx={{ textAlign: 'justify' }}>
          2nd Floor, Tigbauan Municipal Bldg., Tejero St., Brgy. 9, Tigbauan,
          Iloilo
        </Typography>
        <Typography sx={{ textAlign: 'justify' }}>511-8790</Typography>
      </Paper>
    </>
  );
}
