import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import Box from '@mui/material/Box';
import { lazyLoad } from 'utils/loadable';

import { Divider, Typography, Paper } from '@mui/material';
import { Image } from 'mui-image';
export const MarketAndSlaughterHouse = lazyLoad(
  () => import('./MarketAndSlaughterHouse'),
  module => module.MarketAndSlaughterHouseContent,
);

export function MarketAndSlaughterHouseContent(props: any) {
  return (
    <>
      <Helmet>
        <title>Market & Slaughter House</title>
        <meta name="description" content="Market & Slaughter House" />
      </Helmet>
      <Box sx={{ p: '15px' }}>
        <Paper
          variant="outlined"
          sx={{
            // mt: '7.5px',
            p: '12px',
            '& a': {
              textDecoration: 'none',
            },
            '& .announcementHeader': {
              color: 'text.primary',
            },
            '& .announcementHeader:hover': {
              color: 'text.secondary',
            },
            backgroundColor: 'primary.main',
            '@media (max-width: 900px)': {
              textAlign: 'center',
            },
            '@media (min-width: 900px)': {},
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
          }}
        >
          <Typography variant="h4" sx={{ color: 'background.paper' }}>Market & Slaughter House</Typography>
        </Paper>
        <Paper
          variant="outlined"
          sx={{
            my: '15px',
            p: '15px',
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
          }}
        >
          <Typography sx={{ textAlign: 'justify' }}>
            Market Administration – The Municipal Mayor shall exercise direct
            and immediate supervisor , administration and control over public
            market and slaughterhouse personnel. In Market supervisor under the
            direct control, supervision and direction of the Municipal Mayor
            shall exercise supervisor of the public market & slaughterhouse
            operation.
          </Typography>
        </Paper>
        {/* {!props.noHeaderImg ? (
          <Paper variant="outlined" sx={{ my: '15px', p: '15px' }}>
            <Image
              src={require(`../../../assets/localOffice/${props.specialLink}.jpg`)}
              duration={0}
              fit="contain"
              height="40rem"
            />
          </Paper>
        ) : null} */}
        <Paper
          variant="outlined"
          sx={{
            // mt: '7.5px',
            p: '12px',
            '& a': {
              textDecoration: 'none',
            },
            '& .announcementHeader': {
              color: 'text.primary',
            },
            '& .announcementHeader:hover': {
              color: 'text.secondary',
            },
            backgroundColor: 'primary.main',
            '@media (max-width: 900px)': {
              textAlign: 'center',
            },
            '@media (min-width: 900px)': {},
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
          }}
        >
          <Typography variant="h4" sx={{ color: 'background.paper' }}>
            Market & Slaughterhouse Contact Information
          </Typography>
        </Paper>
        <Paper
          variant="outlined"
          sx={{
            my: '15px',
            p: '15px',
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
          }}
        >
          <Typography sx={{ textAlign: 'justify' }}>
            Market & Slaughterhouse Office
          </Typography>
          <Typography sx={{ textAlign: 'justify' }}>
            Tigbauan Public Market, Tejero St., Brgy. 9, Tigbauan, Iloilo
          </Typography>
          <Typography sx={{ textAlign: 'justify' }}>511-2035</Typography>
        </Paper>
      </Box>
    </>
  );
}
