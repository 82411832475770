import React from 'react';
import { Image } from 'mui-image';
import { Link as RouterLink } from 'react-router-dom';
import { Collapse, Grid, Link, Paper, Typography } from '@mui/material';
import { Box } from '@mui/system';

import transparencySeal from '../../../assets/transparencySeal.png';
import foiLogo from '../../../assets/foiLogo.png';
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player';
export const SideNavMobile = props => {
  const [anchor, setAnchor] = React.useState<null | HTMLElement | Boolean>(
    null,
  );
  return (
    <Box>
      <Collapse in={Boolean(anchor)} timeout="auto" unmountOnExit></Collapse>
      <Grid container>
        <Grid item xs={6} sx={{ p: 2 }}>
          <Box sx={{ textAlign: 'center' }}>
            <RouterLink to="/transparency-seal">
              <Image src={transparencySeal} duration={0} fit="contain" />
            </RouterLink>
          </Box>
        </Grid>
        <Grid item xs={6} sx={{ p: 2 }}>
          <a
            href="https://www.foi.gov.ph/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image src={foiLogo} duration={0} fit="contain" />
          </a>
        </Grid>
      </Grid>
      {/* <Grid container>
        <Grid xs={6}>
          <Box sx={{ textAlign: 'center' }}>
            <Image
              src={transparencySeal}
              duration={0}
              fit="contain"

              href=""
            />
          </Box>
        </Grid>
        <Grid xs={6}>
          <a href="https://www.foi.gov.ph/">
            <Image src={foiLogo} duration={0} fit="contain"  />
          </a>
        </Grid>
      </Grid> */}
      <RouterLink to="/mayors-page">
        <Box sx={{ my: '7.5px', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px' }}>
          <Image
            src={require(`../../../assets/icons/tigbauanMayor.png`)}
            duration={0}
            fit="contain"
          />
        </Box>
      </RouterLink>
      <RouterLink to="/tourism/tigbauan-festival">
        <Box sx={{ my: '7.5px', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px' }}>
          <Image
            src={require(`../../../assets/icons/tigbauanTourism.png`)}
            duration={0}
            fit="contain"
          />
        </Box>
      </RouterLink>

      <Box sx={{ my: 3 }}>
        {/* <ReactWeather
  isLoading={isLoading}
  errorMessage={errorMessage}
  data={data}
  lang="en"
  locationLabel="Tigbauan, Iloilo"
  unitsLabels={{ temperature: 'C', windSpeed: 'Km/h' }}
  showForecast
/> */}
        <Paper
          variant="outlined"
          sx={{
            width: '100%',
            mb: 2,
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
          }}
        >
          <Box
            sx={{
              backgroundColor: 'primary.main',
              py: 1,
              color: 'background.paper',
            }}
          >
            <Typography variant="h6" sx={{ textAlign: 'center' }}>
              Tigbauan Location
            </Typography>
          </Box>
          <Box sx={{ p: '1px' }}>
            <iframe
              title="Tigbauan Google Map"
              width="100%"
              height="300"
              style={{
                borderBottomLeftRadius: '5px',
                borderBottomRightRadius: '5px',
              }}
              src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Tigbauan+(Tigbauan)&amp;t=&amp;z=11&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
            ></iframe>
          </Box>
        </Paper>
        {/* <Paper
          variant="outlined"
          sx={{ mb: 2, boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px' }}
        >
          <Box sx={{ backgroundColor: 'primary.main', py: 1 }}>
            <Typography variant="h6" sx={{ textAlign: 'center' }}>
              Tigbauan Hymn
            </Typography>
          </Box>

          <AudioPlayer
            style={{ borderRadius: '0px' }}
            src={require('../../../assets/tigbauanHymn.mp3')}
            showJumpControls={false}
            showSkipControls={false}
            customControlsSection={[
              RHAP_UI.MAIN_CONTROLS,
              RHAP_UI.VOLUME_CONTROLS,
            ]}
            customProgressBarSection={[
              RHAP_UI.CURRENT_TIME,
              RHAP_UI.PROGRESS_BAR,
              RHAP_UI.DURATION,
            ]}
            // other props here
          />
        </Paper> */}
      </Box>
    </Box>
  );
};
