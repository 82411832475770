import React, { useState } from 'react';
import { Image } from 'mui-image';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  AppBar,
  Avatar,
  Button,
  Collapse,
  Divider,
  Drawer,
  Grid,
  IconButton,
  InputBase,
  List,
  ListItemButton,
  MenuItem,
  Paper,
  SvgIcon,
  Toolbar,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { AccessibilityMenu } from './AccessibilityMenu';
import { CustomCollapseList } from './CustomCollapseList';
import { CustomDropdownLink } from './CustomDropdownLink';
import MenuIcon from '@mui/icons-material/Menu';
import { NestedMenuItem } from 'mui-nested-menu';
import SearchIcon from '@mui/icons-material/Search';
import { useSelector } from 'react-redux';
import { GlobalReducer } from 'types/globalReducer';
import { Post } from 'types/post';
import { SideMenuLinks } from './SideMenuLinks';
import { SideNav } from './SideNav';
const home = [
  { n: 'Mission & Vision', l: 'mission-vision' },
  { n: 'About Tigbauan', l: 'about-tigbauan' },
];
const homeMobile = [
  { n: 'Homepage', l: '' },
  { n: 'Mission & Vision', l: 'mission-vision' },
  { n: 'About Tigbauan', l: 'about-tigbauan' },
];
const mayors = [
  { n: "Mayor's Bio", l: 'mayors-bio' },
  { n: 'Office Order', l: 'office-order' },
  { n: 'Executive Order', l: 'executive-order' },
  { n: 'Services', l: 'services' },
  { n: 'Announcements', l: 'announcements' },
  { n: 'Activities', l: 'activities' },
  { n: 'Organizational Chart', l: 'organizational-chart' },
];
const legislative = [
  { n: "Vice Mayor's Bio", l: 'vice-mayors-bio' },
  { n: 'Services', l: 'services' },
  { n: 'Ordinances', l: 'ordinances' },
  { n: 'Resolutions', l: 'resolutions' },
  { n: 'Announcements', l: 'announcements' },
  { n: 'Organizational Chart', l: 'organizational-chart' },
];

const localOffices = [
  { n: 'Accounting Office', l: 'accounting-office' },
  { n: 'Agriculture Office', l: 'agriculture-office' },
  { n: 'Assessors Office', l: 'assessors-office' },
  { n: 'Budget Office', l: 'budget-office' },
  { n: 'Engineering Office', l: 'engineering-office' },
  {
    n: 'Environmental & Natural Resources Office',
    l: 'environmental-and-natural-resources-office',
  },
  { n: 'Health Office', l: 'health-office' },
  { n: 'Local Civil Registrar Office', l: 'local-civil-registrar-office' },
  { n: 'Market and Slaughter House', l: 'market-and-slaughter-house' },
  {
    n: 'Municipal Disaster Risk Reduction Management Office',
    l: 'municipal-disaster-risk-reduction-management-office',
  },
  {
    n: 'Municipal Information Unit',
    l: 'municipal-information-unit',
  },
  { n: 'Planning Office', l: 'planning-office' },
  {
    n: 'Social Welfare & Development Office',
    l: 'social-welfare-and-development-office',
  },
  { n: 'Treasurers Office', l: 'treasurers-office' },
  { n: 'Tourism Office', l: 'tourism-office' },
  {
    n: 'Rural Health Unit',
    l: 'rural-health-unit',
    componentName: 'RuralHealthUnit',
  },
  {
    n: 'Office of the Municipal Mayor',
    l: 'office-of-the-municipal-mayor',
    componentName: 'OfficeOfTheMunicipalMayor',
  },
  {
    n: 'Sangguniang Bayan Office',
    l: 'sangguniang-bayan-office',
    componentName: 'SangguniangBayanOffice',
  },
  {
    n: 'Human Resource Office',
    l: 'human-resource-office',
    componentName: 'HumanResourceOffice',
  },
  {
    n: 'Tourism Office',
    l: 'tourism-office',
    componentName: 'TourismOffice',
  },
  {
    n: 'Public Employment Service Office',
    l: 'public-employment-service-office',
    componentName: 'PublicEmploymentServiceOffice',
  },
  {
    n: 'General Services Office',
    l: 'general-services-office',
    componentName: 'GeneralServicesOffice',
  },
  {
    n: 'Internal Audit Service',
    l: 'internal-audit-service',
    componentName: 'InternalAuditService',
  },
  {
    n: 'Office of the Municipal Administrator',
    l: 'office-of-the-municipal-administrator',
    componentName: 'OfficeOfTheMunicipalAdministrator',
  },
];
const nationalOffices = [
  { n: 'Bureau of Fire Protection', l: 'bureau-of-fire-protection' },
  { n: 'Comelec', l: 'comelec' },
  { n: 'Department of Agrarian Reform', l: 'department-of-agrarian-reform' },
  {
    n: 'Department of Interior and Local Government',
    l: 'department-of-interior-and-local-government',
  },
  { n: 'Population Commmission', l: 'population-commmission' },
  { n: 'Philippine National Police', l: 'philippine-national-police' },
  { n: 'Post Office', l: 'post-office' },
  {
    n: 'Philippine Coast Guard',
    l: 'philippine-coast-guard',
    componentName: 'PhilippineCoastGuard',
  },
];
const tourism = [
  { n: 'Tigbauan Festival', l: 'tigbauan-festival' },
  { n: 'Tigbauan Products', l: 'tigbauan-products' },
  { n: 'Tourists Spots', l: 'tourists-spots' },
];
const tourismMobile = [
  { n: 'Tigbauan Tourism', l: '' },
  { n: 'Tigbauan Festival', l: 'tigbauan-festival' },
  { n: 'Tigbauan Products', l: 'tigbauan-products' },
  { n: 'Tourists Spots', l: 'tourists-spots' },
];
export const ResponsiveAppBar = (props: any) => {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const announcementsData = useSelector((state: GlobalReducer) => {
    if (state.navData.announcements.length > 5)
      return state.navData.announcements.slice(0, 5);
    else return state.navData.announcements;
  });
  const postsData = useSelector((state: GlobalReducer) => {
    if (state.navData.posts.length > 5) return state.navData.posts.slice(0, 5);
    else return state.navData.posts;
  });
  const resolutionData = useSelector((state: GlobalReducer) => {
    if (state.navData.posts.length > 5)
      return state.navData.resolutions.slice(0, 5);
    else return state.navData.resolutions;
  });
  const executiveOrderData = useSelector((state: GlobalReducer) => {
    if (state.navData.posts.length > 5)
      return state.navData.executiveOrders.slice(0, 5);
    else return state.navData.executiveOrders;
  });
  const ordinancesData = useSelector((state: GlobalReducer) => {
    if (state.navData.posts.length > 5)
      return state.navData.ordinances.slice(0, 5);
    else return state.navData.ordinances;
  });

  const toggleDrawer =
    (anchor: any, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  const SearchInput = (props: any) => {
    const [searchStr, setSearchStr] = useState<string>('');
    let navigate = useNavigate();
    return (
      <Box
        sx={{
          display: 'flex',
          height: '100%',
          alignItems: 'center',
          px: '8px',
        }}
      >
        <Paper
          component="form"
          variant="outlined"
          sx={{
            p: '2px 4px',
            display: 'flex',
            alignItems: 'center',
            width: '200px',
            height: '40px',
            borderRadius: 0,
          }}
        >
          {/* <IconButton sx={{ p: '10px' }} aria-label="menu">
          <MenuIcon />
        </IconButton> */}
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search ..."
            inputProps={{ 'aria-label': 'search' }}
            value={searchStr}
            onChange={e => {
              const regex = /[^A-Za-z0-9 ]/g;
              let sanitized = e.currentTarget.value.replace(regex, '');
              sanitized.length > 12
                ? (sanitized = searchStr)
                : // eslint-disable-next-line no-self-assign
                  (sanitized = sanitized);
              setSearchStr(sanitized);
            }}
            onKeyPress={e => {
              if (e.key === 'Enter') {
                navigate(`/search/${searchStr}`);
              }
            }}
          />
          <IconButton
            // type="submit"
            sx={{ p: '10px' }}
            aria-label="search"
            onClick={e => {
              e.preventDefault();
              navigate(`/search/${searchStr}`);
            }}
          >
            <SvgIcon inheritViewBox component={SearchIcon} />
          </IconButton>
          {/* <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" /> */}
        </Paper>
      </Box>
    );
  };

  const list = (anchor: any) => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      // onClick={toggleDrawer('right', false)}
      //onKeyDown={toggleDrawer('right', false)}
    >
      <List
        sx={{
          width: '100%',
          maxWidth: 360,
          bgcolor: 'background.paper',
          mt: '58px',
          '& a': { textDecoration: 'none' },
          '& button': { color: 'black' },
        }}
        component="nav"
        aria-labelledby="nested-list-subheader"
        // subheader={
        //   <ListSubheader component="div" id="nested-list-subheader">
        //     Nested List Items
        //   </ListSubheader>
        // }
      >
        <Box sx={{ display: 'flex', placeContent: 'center', my: 2 }}>
          <SearchInput />
        </Box>

        {CustomCollapseList(homeMobile, 'Home', 'Home', 'home')}
        {CustomCollapseList(
          mayors,
          'Mayors Page',
          "Mayor's Page",
          'mayors-page',
        )}
        {CustomCollapseList(
          legislative,
          'legislative',
          'Legislative',
          'legislative',
        )}
        {CustomCollapseList(
          [],
          'localOffices',
          'Local Offices',
          'local-offices',
          [
            <Collapse in={true} key={'collapse-1'} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {localOffices.map(val => (
                  <ListItemButton
                    sx={{ pl: 5 }}
                    onClick={() => {}}
                    key={`${val.l}-${val.n}`}
                    disableRipple
                  >
                    <RouterLink
                      style={{
                        width: '100%',
                        height: '100%',
                        padding: '5px',
                        color: 'black',
                        textAlign: 'left',
                      }}
                      to={`/local-offices/${val.l}`}
                    >
                      {val.n}
                    </RouterLink>
                  </ListItemButton>
                ))}
              </List>
            </Collapse>,
          ],
        )}
        {CustomCollapseList(
          [],
          'nationalOffices',
          'National Offices',
          'national-offices',
          [
            <Collapse in={true} key={'collapse-2'} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {nationalOffices.map(val => (
                  <ListItemButton
                    sx={{ pl: 5 }}
                    onClick={() => {}}
                    key={`${val.l}-${val.n}`}
                    disableRipple
                  >
                    <RouterLink
                      style={{
                        width: '100%',
                        height: '100%',
                        padding: '5px',
                        color: 'black',
                        textAlign: 'left',
                      }}
                      to={`/national-offices/${val.l}`}
                    >
                      {val.n}
                    </RouterLink>
                  </ListItemButton>
                ))}
              </List>
            </Collapse>,
          ],
        )}
        {CustomCollapseList(tourismMobile, 'tourism', 'Tourism', 'tourism')}
        <ListItemButton>
          <RouterLink to="/bids-and-awards">
            <Button>Bids and Awards</Button>
          </RouterLink>
        </ListItemButton>
        <ListItemButton>
          <RouterLink to="/bids-and-awards">
            <Button>Full Disclosure Policy</Button>
          </RouterLink>
        </ListItemButton>

        <ListItemButton>
          <RouterLink to="/contact-us">
            <Button>Contact Us</Button>
          </RouterLink>
        </ListItemButton>
        <ListItemButton>
          <RouterLink to="/about">
            <Button>About</Button>
          </RouterLink>
        </ListItemButton>
      </List>
      <SideNav/>
      {/* <Paper
        variant="outlined"
        sx={{
          pb: '15px',

          borderRadius: 0,
        }}
      >
        <Box sx={{ backgroundColor: 'primary.main', py: 1 }}>
          <Typography variant="h6" sx={{ textAlign: 'center' }}>
            Contact Information
          </Typography>
        </Box>
        <Box sx={{ px: 2 }}>
          <Typography variant="h6">Address</Typography>
          <Typography sx={{}}>
            Tejero street 5021 Tigbauan, Philippines
          </Typography>
          <Divider sx={{ my: '7.5px' }} />
          <Typography variant="h6">Telephone</Typography>
          <Typography sx={{}}>(033) 321 2474</Typography>
          <Divider sx={{ my: '7.5px' }} />
          <Typography variant="h6">Email</Typography>
          <Typography>mpdctigbauan@gmail.com</Typography>
        </Box>
      </Paper>
      <Paper
        variant="outlined"
        sx={{
          // px: '7.5px',
          borderRadius: 0,
          '& a': {
            textDecoration: 'none',
          },
          '& .postTitleLink': {
            color: '#2ba6cb !important',
            // my: '5px',
          },
          '& .postTitleLink:hover': { color: '#258faf !important' },
          boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
        }}
      >
        <Box sx={{ backgroundColor: 'primary.main', py: 1 }}>
          <Typography variant="h6" sx={{ textAlign: 'center' }}>
            Announcements
          </Typography>
        </Box>
        <Box sx={{ p: '7.5px' }}>
          {announcementsData.map((value: Post, index) => {
            return (
              <Box key={index + 'pbx'}>
                <RouterLink to={`/posts/${value.id}`}>
                  <Typography key={index + 'p'} className="postTitleLink">
                    {value.title}
                  </Typography>
                </RouterLink>
                <Divider sx={{ mb: '8px' }} />
              </Box>
            );
          })}
        </Box>
      </Paper>
      <Paper
        variant="outlined"
        sx={{
          // px: '7.5px',
          borderRadius: 0,
          '& a': {
            textDecoration: 'none',
          },
          '& .postTitleLink': {
            color: '#2ba6cb !important',
            // my: '5px',
          },
          '& .postTitleLink:hover': { color: '#258faf !important' },
          boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
        }}
      >
        <Box sx={{ backgroundColor: 'primary.main', py: 1 }}>
          <Typography variant="h6" sx={{ textAlign: 'center' }}>
            Latest Post
          </Typography>
        </Box>
        <Box sx={{ p: '7.5px' }}>
          {postsData.map((value: Post, index) => {
            return (
              <Box key={index}>
                <RouterLink to={`/posts/${value.id}`}>
                  <Typography key={index + 'p'} className="postTitleLink">
                    {value.title}
                  </Typography>
                </RouterLink>
                <Divider sx={{ mb: '8px' }} />
              </Box>
            );
          })}
        </Box>
      </Paper>
      <Paper
        variant="outlined"
        sx={{
          // px: '7.5px',
          borderRadius: 0,
          '& a': {
            textDecoration: 'none',
          },
          '& .postTitleLink': {
            color: '#2ba6cb !important',
            // my: '5px',
          },
          '& .postTitleLink:hover': { color: '#258faf !important' },
          boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
        }}
      >
        <Box sx={{ backgroundColor: 'primary.main', py: 1 }}>
          <Typography variant="h6" sx={{ textAlign: 'center' }}>
            Executive Orders
          </Typography>
        </Box>
        <Box sx={{ p: '7.5px' }}>
          {executiveOrderData.map((value: Post, index) => {
            return (
              <Box key={index}>
                <RouterLink to={`/posts/${value.id}`}>
                  <Typography key={index + 'p'} className="postTitleLink">
                    {value.title}
                  </Typography>
                </RouterLink>
                <Divider sx={{ mb: '8px' }} />
              </Box>
            );
          })}
        </Box>
      </Paper>
      <Paper
        variant="outlined"
        sx={{
          // px: '7.5px',
          borderRadius: 0,
          '& a': {
            textDecoration: 'none',
          },
          '& .postTitleLink': {
            color: '#2ba6cb !important',
            // my: '5px',
          },
          '& .postTitleLink:hover': { color: '#258faf !important' },
          boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
        }}
      >
        <Box sx={{ backgroundColor: 'primary.main', py: 1 }}>
          <Typography variant="h6" sx={{ textAlign: 'center' }}>
            Resolutions
          </Typography>
        </Box>
        <Box sx={{ p: '7.5px' }}>
          {resolutionData.map((value: Post, index) => {
            return (
              <Box key={index}>
                <RouterLink to={`/posts/${value.id}`}>
                  <Typography key={index + 'p'} className="postTitleLink">
                    {value.title}
                  </Typography>
                </RouterLink>
                <Divider sx={{ mb: '8px' }} />
              </Box>
            );
          })}
        </Box>
      </Paper>
      <Paper
        variant="outlined"
        sx={{
          // px: '7.5px',
          mb: 2,
          borderRadius: 0,
          '& a': {
            textDecoration: 'none',
          },
          '& .postTitleLink': {
            color: '#2ba6cb !important',
            // my: '5px',
          },
          '& .postTitleLink:hover': { color: '#258faf !important' },
          boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
        }}
      >
        <Box sx={{ backgroundColor: 'primary.main', py: 1 }}>
          <Typography variant="h6" sx={{ textAlign: 'center' }}>
            Ordinances
          </Typography>
        </Box>
        <Box sx={{ p: '7.5px' }}>
          {ordinancesData.map((value: Post, index) => {
            return (
              <Box key={index}>
                <RouterLink to={`/posts/${value.id}`}>
                  <Typography key={index + 'p'} className="postTitleLink">
                    {value.title}
                  </Typography>
                </RouterLink>
                <Divider sx={{ mb: '8px' }} />
              </Box>
            );
          })}
        </Box>
      </Paper> */}
      <Box

      >
        <SideMenuLinks />
      </Box>
    </Box>
  );

  const MobileNav = () => {
    return <></>;
  };

  const LGNav = () => {
    return (
      <Grid
        sx={{
          maxHeight: '58px',
          width: '80rem',
          p: 0,
          m: 0,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'transparent',
          color: 'black',
          // display: { xs: 'none', md: 'flex' },
          '@media (max-width: 1330px)': { display: 'none' },
          '@media (min-width: 1330px)': { display: 'flex' },
          '& .btnNavPills': {
            m: 0,
            py: 0,
            whiteSpace: 'nowrap',
            textTransform: 'none',
            backgroundColor: 'transparent',
            height: '100%',
            width: '100%',
            zIndex: 2001,
            color: 'black',
            fontFamily:
              '"Helvetica Neue","Helvetica",Helvetica,Arial,sans-serif',
            fontSize: '0.85rem',
          },

          '& .btnNavPills:hover': {
            backgroundColor: '#e4e4e4',
            borderRadius: '0px',
          },
          '& .btnNavPillContainer': {
            textAlign: 'center',
            backgroundColor: 'transparent',
            height: '58px',
            pointerEvents: 'auto',
            borderRight: '0.0625rem solid #ebebeb',
            borderLeft: '0.0625rem solid #ebebeb',
            zIndex: 2000,
          },
          '& .btnNavPilContainerFullHeight': { height: '58px' },
          '& .MuiButton-root': {},
        }}
      >
        <Grid
          className="btnNavPillContainer"
          sx={{ borderLeft: '0px !important' }}
          item
          xs
        >
          {/* <Box sx={{}}>
  <Image
    src={phSeal}
    height="36px"
    width="36px"
    duration={0}
    fit="cover"
  />

</Box> */}

          <Button
            href="http://www.gov.ph/"
            className=""
            sx={{
              justifyContent: 'left',
              width: '100%',
              height: '100%',
              textDecoration: 'none',
              color: 'black',
              fontFamily: "'Arial' ,sans-serif !important",
              fontWeight: 400,
              fontSize: '1.19rem',
              ':hover': {
                backgroundColor: 'transparent',
                textDecoration: 'none',
              },
            }}
          >
            GOVPH
          </Button>
        </Grid>
        <Grid
          className="btnNavPillContainer"
          id="btnNavHomePillContainer"
          item
          xs
        >
          {CustomDropdownLink(home, 'Home', 'Home', 'home', undefined, '/')}
        </Grid>
        <Grid className="btnNavPillContainer" item xs>
          {CustomDropdownLink(
            mayors,
            'Mayors Page',
            "Mayor's Page",
            'mayors-page',
            undefined,
            'mayors-page/mayors-bio',
          )}
        </Grid>
        <Grid className="btnNavPillContainer" item xs>
          {CustomDropdownLink(
            legislative,
            'Legislative',
            'Legislative',
            'legislative',
            undefined,
            'legislative/vice-mayors-bio',
          )}
        </Grid>
        <Grid className="btnNavPillContainer" item xs>
          {CustomDropdownLink([], 'Offices', 'Offices', 'offices', [
            <NestedMenuItem
              label={'Local Offices'}
              key="nestedMenu-1"
              parentMenuOpen={true}
            >
              {localOffices.map(val => (
                <MenuItem
                  onClick={() => {}}
                  key={`${val.l}-${val.n}`}
                  disableRipple
                  sx={{ '& a': { textDecoration: 'none' } }}
                >
                  <RouterLink
                    style={{
                      width: '100%',
                      height: '100%',
                      padding: '5px',
                      color: 'black',
                      textAlign: 'left',
                    }}
                    to={`/local-offices/${val.l}`}
                  >
                    {val.n}
                  </RouterLink>
                </MenuItem>
              ))}
            </NestedMenuItem>,
            <NestedMenuItem
              label={'National Offices'}
              key="nestedMenu-2"
              parentMenuOpen={true}
            >
              {nationalOffices.map(val => (
                <MenuItem
                  sx={{ '& a': { textDecoration: 'none' } }}
                  onClick={() => {}}
                  key={`${val.l}-${val.n}`}
                  disableRipple
                >
                  <RouterLink
                    style={{
                      width: '100%',
                      height: '100%',
                      padding: '5px',
                      color: 'black',
                      textAlign: 'left',
                    }}
                    to={`/national-offices/${val.l}`}
                  >
                    {val.n}
                  </RouterLink>
                </MenuItem>
              ))}
            </NestedMenuItem>,
          ])}
        </Grid>
        <Grid className="btnNavPillContainer" item xs>
          {CustomDropdownLink(tourism, 'tourism', 'Tourism', 'tourism')}
        </Grid>
        <Grid className="btnNavPillContainer" item xs>
          <SearchInput />
        </Grid>
        <Grid className="btnNavPillContainer" item xs={0.85}>
          <AccessibilityMenu
            scrollToBottom={props.scrollToBottom}
            scrollToContent={props.scrollToContent}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          maxHeight: '58px',
          height: '58px',
          px: '0px !important',
          backgroundColor: '#f7f7f7',
          zIndex: 1500,
        }}
      >
        <Toolbar
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            //width: '80rem',
            height: '100%',
            width: '100%',
            // mx: 'auto',
            '& .MuiToolbar-root': { height: '100px' },
          }}
          disableGutters
          variant={'dense'}
        >
          <Grid
            container
            sx={{
              '@media (max-width: 1330px)': { display: 'flex' },
              '@media (min-width: 1330px)': { display: 'none' },
              width: '100%',
              height: '100%',
            }}
          >
            <Grid item xs sx={{ width: '100%', alignSelf: 'center' }}>
              <Button
                href="http://www.gov.ph/"
                target="_blank"
                rel="noopener noreferrer"
                className=""
                sx={{
                  justifyContent: 'left',
                  width: '100%',
                  height: '100%',
                  textDecoration: 'none',
                  color: 'black',
                  fontFamily: "'Arial' ,sans-serif !important",
                  fontWeight: 400,
                  fontSize: '1.19rem',
                  ':hover': {
                    backgroundColor: 'transparent',
                    textDecoration: 'none',
                  },
                }}
              >
                GOVPH
              </Button>
            </Grid>
            <Grid
              item
              xs
              sx={{ textAlign: 'right', width: '100%', alignSelf: 'center' }}
            >
              <IconButton
                aria-label="open drawer"
                onClick={toggleDrawer('right', true)}
                edge="start"
                sx={{ mr: 2, color: 'black' }}
              >
                <Typography sx={{ pr: '10px', fontWeight: 600 }}>
                  Menu
                </Typography>
                <MenuIcon />
              </IconButton>
            </Grid>
          </Grid>
          <MobileNav />
          <LGNav />
        </Toolbar>
      </AppBar>
      <React.Fragment>
        {/* <Button onClick={toggleDrawer('right', true)}>{'right'}</Button> */}
        <Drawer
          anchor={'right'}
          open={state['right']}
          onClose={toggleDrawer('right', false)}
        >
          {list('right')}
        </Drawer>
      </React.Fragment>
    </>
  );
};
