import {
  Paper,
  Typography,
  Divider,
  Button,
  Grid,
  Avatar,
} from '@mui/material';
import { Box } from '@mui/system';
import React, { useRef } from 'react';
import { Image } from 'mui-image';
import { Link as RouterLink } from 'react-router-dom';
import { GlobalReducer } from 'types/globalReducer';
import { useSelector } from 'react-redux';
import { Post } from 'types/post';
import useWindowDimensions from '../../components/useWindowDimensions';
import sideNavData from '../../../assets/sidenavData.json';

export const SideMenuLinks = () => {
  return (
    <Box
      sx={{
        mt: '15px',
        textAlign: 'center',
        height: '100%',
        display: 'flex',
        justifyContent: 'start',
        flexDirection: 'column',
        // px: 1,
        '& .MuiButton-root': {
          width: '100%',
          height: '80px',
          //my: '12px',

          // boxShadow: 'none',
          backgroundColor: 'secondary.main',
          borderRadius: 1.5,
          // borderTopLeftRadius: 50,
          // borderBottomLeftRadius: 50,
          //pr: '5px',
        },
        '& h6': {
          width: 'inherit',
          fontWeight: 500,
          fontSize: '0.80rem',
        },
        '& a': { textDecoration: 'none' },
        '& .MuiTypography-root': { color: 'white' },
      }}
    >
      {sideNavData.map(x => {
        return (
          <Button
            href={x.link}
            target="_blank"
            rel="noopener noreferrer"
            sx={{ p: '0px', m: 0, my: 1.5 }}
            variant="contained"
            // className="box"
          >
            <Grid container>
              <Grid item xs sx={{ textAlign: 'center', alignSelf: 'center' }}>
                <Typography variant="h6">{x.title}</Typography>
              </Grid>
            </Grid>
          </Button>
        );
      })}
    </Box>
  );
};

// export const SideMenuLinks = () => {
//   return (
//     <>
//       <RouterLink to="/jobs">
//         <Button
//           disableRipple
//           sx={{ p: '0px' }}
//           variant="contained"
//           className="box"
//         >
//           <Grid container>
//             {/* <Grid
//               sx={{
//                 textAlign: '-webkit-center',
//               }}
//               item
//             >
//               <Avatar
//                 sx={{
//                   width: 100,
//                   height: 100,
//                   bgcolor: 'primary.main',
//                   ml: '-1px',
//                 }}
//               >
//                 <Image
//                   src={require(`../../../assets/icons/job.png`)}
//                   duration={0}
//                   fit="contain"
//                   width="60px"
//                 />
//               </Avatar>
//             </Grid> */}
//             <Grid item xs sx={{ textAlign: 'center', alignSelf: 'center' }}>
//               <Typography variant="h6">Jobs</Typography>
//             </Grid>
//           </Grid>
//         </Button>
//       </RouterLink>

//       <RouterLink to="/downloads">
//         <Button
//           disableRipple
//           sx={{ p: '0px' }}
//           variant="contained"
//           className="box"
//         >
//           <Grid container>
//             {/* <Grid
//               sx={{
//                 textAlign: '-webkit-center',
//               }}
//               item
//             >
//               <Avatar
//                 sx={{
//                   width: 100,
//                   height: 100,
//                   bgcolor: 'primary.main',
//                   ml: '-1px',
//                 }}
//               >
//                 <Image
//                   src={require(`../../../assets/icons/download.png`)}
//                   duration={0}
//                   fit="contain"
//                   width="60px"
//                 />
//               </Avatar>
//             </Grid> */}
//             <Grid item xs sx={{ textAlign: 'center', alignSelf: 'center' }}>
//               <Typography variant="h6">Downloads</Typography>
//             </Grid>
//           </Grid>
//         </Button>
//       </RouterLink>

//       <RouterLink to="/announcements">
//         <Button
//           disableRipple
//           sx={{ p: '0px' }}
//           variant="contained"
//           className="box"
//         >
//           <Grid container>
//             {/* <Grid
//               sx={{
//                 textAlign: '-webkit-center',
//               }}
//               item
//             >
//               <Avatar
//                 sx={{
//                   width: 100,
//                   height: 100,
//                   bgcolor: 'primary.main',
//                   ml: '-1px',
//                 }}
//               >
//                 <Image
//                   src={require(`../../../assets/icons/loudspeaker.png`)}
//                   duration={0}
//                   fit="contain"
//                   width="60px"
//                 />
//               </Avatar>
//             </Grid> */}
//             <Grid item xs sx={{ textAlign: 'center', alignSelf: 'center' }}>
//               <Typography variant="h6">News & Announcements</Typography>
//             </Grid>
//           </Grid>
//         </Button>
//       </RouterLink>

//       <RouterLink to="/home/about-tigbauan">
//         <Button
//           disableRipple
//           sx={{ p: '0px' }}
//           variant="contained"
//           className="box"
//         >
//           <Grid container>
//             {/* <Grid
//               sx={{
//                 textAlign: '-webkit-center',
//               }}
//               item
//             >
//               <Avatar
//                 sx={{
//                   width: 100,
//                   height: 100,
//                   bgcolor: 'primary.main',
//                   ml: '-1px',
//                 }}
//               >
//                 <Image
//                   src={require(`../../../assets/icons/asia.png`)}
//                   duration={0}
//                   fit="contain"
//                   width="60px"
//                 />
//               </Avatar>
//             </Grid> */}
//             <Grid item xs sx={{ textAlign: 'center', alignSelf: 'center' }}>
//               <Typography variant="h6">
//                 Tigbauan
//                 <br /> Profile
//               </Typography>
//             </Grid>
//           </Grid>
//         </Button>
//       </RouterLink>
//       <RouterLink to="/local-offices/engineering-office">
//         <Button
//           disableRipple
//           sx={{ p: '0px' }}
//           variant="contained"
//           className="box"
//         >
//           <Grid container>
//             {/* <Grid
//               sx={{
//                 textAlign: '-webkit-center',
//               }}
//               item
//             >
//               <Avatar
//                 sx={{
//                   width: 100,
//                   height: 100,
//                   bgcolor: 'primary.main',
//                   ml: '-1px',
//                 }}
//               >
//                 <Image
//                   src={require(`../../../assets/icons/project.png`)}
//                   duration={0}
//                   fit="contain"
//                   width="60px"
//                 />
//               </Avatar>
//             </Grid> */}
//             <Grid item xs sx={{ textAlign: 'center', alignSelf: 'center' }}>
//               <Typography variant="h6">Programs & Projects</Typography>
//             </Grid>
//           </Grid>
//         </Button>
//       </RouterLink>
//       <RouterLink to="/posts/38">
//         <Button
//           disableRipple
//           sx={{ p: '0px' }}
//           variant="contained"
//           className="box"
//         >
//           <Grid container>
//             {/* <Grid
//               sx={{
//                 textAlign: '-webkit-center',
//               }}
//               item
//             >
//               <Avatar
//                 sx={{
//                   width: 100,
//                   height: 100,
//                   bgcolor: 'primary.main',
//                   ml: '-1px',
//                 }}
//               >
//                 <Image
//                   src={require(`../../../assets/icons/clupZoning.jpg`)}
//                   duration={0}
//                   fit="contain"
//                   width="60px"
//                 />
//               </Avatar>
//             </Grid> */}
//             <Grid item xs sx={{ textAlign: 'center', alignSelf: 'center' }}>
//               <Typography variant="h6">CLUP / Zoning Ordinance</Typography>
//             </Grid>
//           </Grid>
//         </Button>
//       </RouterLink>
//       <Button
//         href="https://www.larrytueres.com/"
//         target="_blank"
//         rel="noopener noreferrer"
//         disableRipple
//         sx={{ p: '0px' }}
//         variant="contained"
//         className="box"
//       >
//         <Grid container>
//           {/* <Grid
//             sx={{
//               textAlign: '-webkit-center',
//             }}
//             item
//           >
//             <Avatar
//               sx={{
//                 width: 100,
//                 height: 100,
//                 bgcolor: 'primary.main',
//                 ml: '-1px',
//               }}
//             >
//               <Image
//                 src={require(`../../../assets/icons/TAC.jpg`)}
//                 duration={0}
//                 fit="contain"
//                 width="60px"
//               />
//             </Avatar>
//           </Grid> */}
//           <Grid item xs sx={{ textAlign: 'center', alignSelf: 'center' }}>
//             <Typography variant="h6">
//               Athletic
//               <br /> Club
//             </Typography>
//           </Grid>
//         </Grid>
//       </Button>

//       <Button
//         href="https://drive.google.com/file/d/1Eq9xz_uCUQsUumHp8VYD0VkqO_4VfWrv/view?fbclid=IwAR05s9MIBjVwKL_1MKPZkhfz3GviYr4apj1Q2qrvXrD2MxYg6K6-sjR1jB8"
//         disableRipple
//         target="_blank"
//         rel="noopener noreferrer"
//         sx={{ p: '0px' }}
//         variant="contained"
//         className="box"
//       >
//         <Grid container>
//           {/* <Grid
//             sx={{
//               textAlign: '-webkit-center',
//             }}
//             item
//           >
//             <Avatar
//               sx={{
//                 width: 100,
//                 height: 100,
//                 bgcolor: 'primary.main',
//                 ml: '-1px',
//               }}
//             >
//               <Image
//                 src={require(`../../../assets/icons/organization.png`)}
//                 duration={0}
//                 fit="contain"
//                 width="60px"
//               />
//             </Avatar>
//           </Grid> */}
//           <Grid item xs sx={{ textAlign: 'center', alignSelf: 'center' }}>
//             <Typography variant="h6">Civil Society Organization</Typography>
//           </Grid>
//         </Grid>
//       </Button>
//       <Button
//         href="https://www.facebook.com/mdrrmc.tigbauan?lst=100003723862630%3A100006972552877%3A1541658479"
//         disableRipple
//         target="_blank"
//         rel="noopener noreferrer"
//         sx={{ p: '0px' }}
//         variant="contained"
//         className="box"
//       >
//         <Grid container>
//           {/* <Grid
//             sx={{
//               textAlign: '-webkit-center',
//             }}
//             item
//           >
//             <Avatar
//               sx={{
//                 width: 100,
//                 height: 100,
//                 bgcolor: 'primary.main',
//                 ml: '-1px',
//               }}
//             >
//               <Image
//                 src={require(`../../../assets/icons/MDRRMC.png`)}
//                 duration={0}
//                 fit="contain"
//                 width="60px"
//               />
//             </Avatar>
//           </Grid> */}
//           <Grid item xs sx={{ textAlign: 'center', alignSelf: 'center' }}>
//             <Typography variant="h6">MDRRMO</Typography>
//           </Grid>
//         </Grid>
//       </Button>
//     </>
//   );
// };
