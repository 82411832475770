import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import Box from '@mui/material/Box';
import { lazyLoad } from 'utils/loadable';
import { Divider, Typography } from '@mui/material';

export const About = lazyLoad(
  () => import('./About'),
  module => module.AboutContent,
);

export function AboutContent(props: any) {
  return (
    <>
      <Helmet>
        <title>About</title>
        <meta name="description" content="About" />
      </Helmet>
      <Box sx={{ p: '15px' }}>
        <Typography variant="h4" sx={{ color: 'background.paper' }}>About</Typography>
        <Divider />
      </Box>
    </>
  );
}
