import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import Box from '@mui/material/Box';
import { lazyLoad } from 'utils/loadable';
import { Paper, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { GlobalReducer } from 'types/globalReducer';
import { useEffect, useState } from 'react';
import ContentAPI from 'api/contentAPI';
import { Post } from 'types/post';
import EnhancedTable from './components/EnhancedTable';
import useWindowDimensions from 'app/components/useWindowDimensions';
import { specialPages, TlinkNames } from 'types/links';
import { AccountingOffice } from '../LocalOfficePage/AccountingOffice';
import { AgricultureOffice } from '../LocalOfficePage/AgricultureOffice';
import { AssessorsOffice } from '../LocalOfficePage/AssessorsOffice';
import { BudgetOffice } from '../LocalOfficePage/BudgetOffice';
import { EngineeringOffice } from '../LocalOfficePage/EngineeringOffice';
import { EnvironmentAndNaturalResourcesOffice } from '../LocalOfficePage/EnvironmentAndNaturalResourcesOffice';
import { HealthOffice } from '../LocalOfficePage/HealthOffice';
import { LocalCivilRegistrarOffice } from '../LocalOfficePage/LocalCivilRegistrarOffice';
import { MarketAndSlaughterHouse } from '../LocalOfficePage/MarketAndSlaughterHouse';
import { MunicipalDisasterRiskReductionOffice } from '../LocalOfficePage/MunicipalDisasterRiskReductionOffice';
import { PlanningOffice } from '../LocalOfficePage/PlanningOffice';
import { SocialWelfareAndDevelopmentOffice } from '../LocalOfficePage/SocialWelfareAndDevelopmentOffice';
import { TreasurersOffice } from '../LocalOfficePage/TreasurersOffice';
import { GlobalColumn } from 'app/components/globalPostCell';
import { TourismOffice } from '../LocalOfficePage/TourismOffice';
import { BureauOfFireProtection } from '../NationalOfficePage/BureauOfFireProtection';
import { Comelec } from '../NationalOfficePage/Comelec';
import { DepartmentOfAgrarianReform } from '../NationalOfficePage/DepartmentOfAgrarianReform';
import { DILG } from '../NationalOfficePage/DILG';
import { PopulationCommission } from '../NationalOfficePage/PopulationCommission';
import { PNP } from '../NationalOfficePage/PNP';
import { PostOffice } from '../NationalOfficePage/PostOffice';
import { BidsAndAwards } from '../BidsAndAwards';
import { MunicipalInformationUnit } from '../LocalOfficePage/MunicipalInformationUnit';
import { UnderConstructionPage } from './UnderConstructionPage';
export const PostsPage = lazyLoad(
  () => import('./PostsPage'),
  module => module.PostsPageContent,
);

interface ISpecialPages {
  n: string;
  l: string;
}

const SpecialPagesContainer = (props: any) => {
  const _specialPage: ISpecialPages = props.specialPage;
  // const [renderItem, setRenderItem] = useState<React.ReactFragment>(
  //   <React.ReactFragment/>
  // );
  //console.log(_specialPage);

  switch (_specialPage !== undefined ? _specialPage.l : '') {
    case 'accounting-office':
      return (
        <AccountingOffice specialLink={_specialPage.l} noHeaderImg={false} />
      );

    case 'agriculture-office':
      return (
        <AgricultureOffice specialLink={_specialPage.l} noHeaderImg={false} />
      );

    case 'assessors-office':
      return (
        <AssessorsOffice specialLink={_specialPage.l} noHeaderImg={false} />
      );

    case 'budget-office':
      return <BudgetOffice specialLink={_specialPage.l} noHeaderImg={false} />;

    case 'engineering-office':
      return (
        <EngineeringOffice specialLink={_specialPage.l} noHeaderImg={true} />
      );

    case 'environmental-and-natural-resources-office':
      return (
        <EnvironmentAndNaturalResourcesOffice
          specialLink={_specialPage.l}
          noHeaderImg={true}
        />
      );

    case 'health-office':
      return <HealthOffice specialLink={_specialPage.l} noHeaderImg={false} />;

    case 'local-civil-registrar-office':
      return (
        <LocalCivilRegistrarOffice
          specialLink={_specialPage.l}
          noHeaderImg={false}
        />
      );

    case 'market-and-slaughter-house':
      return (
        <MarketAndSlaughterHouse
          specialLink={_specialPage.l}
          noHeaderImg={false}
        />
      );

    case 'municipal-disaster-risk-reduction-management-office':
      return (
        <MunicipalDisasterRiskReductionOffice
          specialLink={_specialPage.l}
          noHeaderImg={true}
        />
      );
    case 'municipal-information-unit':
      return (
        <MunicipalInformationUnit
          specialLink={_specialPage.l}
          noHeaderImg={true}
        />
      );

    case 'planning-office':
      return (
        <PlanningOffice specialLink={_specialPage.l} noHeaderImg={false} />
      );

    case 'social-welfare-and-development-office':
      return (
        <SocialWelfareAndDevelopmentOffice
          specialLink={_specialPage.l}
          noHeaderImg={true}
        />
      );

    case 'treasurers-office':
      return (
        <TreasurersOffice specialLink={_specialPage.l} noHeaderImg={true} />
      );
    case 'tourism-office':
      return <TourismOffice specialLink={_specialPage.l} noHeaderImg={true} />;
    case 'bureau-of-fire-protection':
      return (
        <BureauOfFireProtection
          specialLink={_specialPage.l}
          noHeaderImg={true}
        />
      );
    case 'comelec':
      return <Comelec specialLink={_specialPage.l} noHeaderImg={true} />;
    case 'department-of-agrarian-reform':
      return (
        <DepartmentOfAgrarianReform
          specialLink={_specialPage.l}
          noHeaderImg={true}
        />
      );

    case 'department-of-interior-and-local-government':
      return <DILG specialLink={_specialPage.l} noHeaderImg={true} />;

    case 'population-commmission':
      return (
        <PopulationCommission specialLink={_specialPage.l} noHeaderImg={true} />
      );

    case 'philippine-national-police':
      return <PNP specialLink={_specialPage.l} noHeaderImg={true} />;

    case 'post-office':
      return <PostOffice specialLink={_specialPage.l} noHeaderImg={true} />;
    case 'bids-and-awards':
      return <BidsAndAwards specialLink={_specialPage.l} noHeaderImg={true} />;

    case 'rural-health-unit':
      return (
        <UnderConstructionPage
          specialLink={_specialPage.l}
          noHeaderImg={true}
        />
      );
    case 'office-of-the-municipal-mayor':
      return (
        <UnderConstructionPage
          specialLink={_specialPage.l}
          noHeaderImg={true}
        />
      );
    case 'sangguniang-bayan-office':
      return (
        <UnderConstructionPage
          specialLink={_specialPage.l}
          noHeaderImg={true}
        />
      );
    case 'human-resource-office':
      return (
        <UnderConstructionPage
          specialLink={_specialPage.l}
          noHeaderImg={true}
        />
      );
    case 'public-employment-service-office':
      return (
        <UnderConstructionPage
          specialLink={_specialPage.l}
          noHeaderImg={true}
        />
      );
    case 'general-services-office':
      return (
        <UnderConstructionPage
          specialLink={_specialPage.l}
          noHeaderImg={true}
        />
      );
    case 'internal-audit-service':
      return (
        <UnderConstructionPage
          specialLink={_specialPage.l}
          noHeaderImg={true}
        />
      );
    case 'office-of-the-municipal-administrator':
      return (
        <UnderConstructionPage
          specialLink={_specialPage.l}
          noHeaderImg={true}
        />
      );
    case 'philippine-coast-guard':
      return (
        <UnderConstructionPage
          specialLink={_specialPage.l}
          noHeaderImg={true}
        />
      );

    default:
  }

  return <></>;
};
export function PostsPageContent(props: any) {
  const [selectedLink, setSelectedLink] = useState<ISpecialPages>();
  const [posts, setPosts] = useState<Post>();
  const [triggerOnce, setTriggerOnce] = useState<boolean>(false);
  const globalColumn = React.useMemo(() => [GlobalColumn], []);
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const selectedNav: TlinkNames | string = useSelector(
    (state: GlobalReducer) => state.navData.selectedNav,
  );
  const { height, width } = useWindowDimensions();

  useEffect(() => {
    setSelectedLink(specialPages.find(x => x.l === selectedNav));
    //console.log(selectedNav);
  }, [selectedNav]);

  useEffect(() => {
    setTriggerOnce(triggerOnce ? true : false);
    async function getPostByUniqueLink() {
      // switch (selectedLink?.c) {
      //   case 'basic':
      //     setColumnType(basicColumn);
      //     break;
      //   case 'post':
      //     setColumnType(postColumn);
      //     break;
      //   default:
      //     setColumnType(postColumn);
      //     break;
      // }

      const _filter = {
        ...(selectedNav === 'announcements' || selectedNav === 'posts'
          ? { uniqueLink: selectedNav ? selectedNav : undefined }
          : { postType: selectedNav ? selectedNav : undefined }),
      };
      //console.log(_filter);
      //@ts-ignore
      const data: Post = await ContentAPI.getPosts(dispatch, { ..._filter });
      //console.log(selectedNav, data);
      setPosts(data);
      //console.log('UNIQUELINK DATA ===> ', data);
      //console.log(data);
    }
    getPostByUniqueLink();
    //@ts-ignore

    //setAnnouncement(findAnnouncementByID(Number(params.announcementId)));
    //console.log(findAnnouncementByID(Number(params.announcementId)));
  }, [selectedNav, triggerOnce]);

  //console.log(pathname.includes('testing'));
  //console.log(specialPages.find(x => x.l === selectedNav));

  return (
    <>
      <Helmet>
        <title>{selectedLink?.n}</title>
        <meta name="description" content={selectedLink?.n} />
      </Helmet>
      <SpecialPagesContainer specialPage={selectedLink} />

      <Box
        sx={{
          '@media (min-width: 1330px)': {
            // p: ' 15px',
          },
          '@media (max-width: 1330px)': { textAlign: 'center' },
        }}
      >
        {/* {pathname.includes('national-offices') && (
          <Box sx={{ pb: 3 }}>
            <Typography variant="h4" sx={{ color: 'background.paper' }}>{selectedLink?.n}</Typography>
            <Divider />
          </Box>
        )} */}
        <Paper
          variant="outlined"
          sx={{
            // mt: '7.5px',
            p: '12px',
            '& a': {
              textDecoration: 'none',
            },
            '& .announcementHeader': {
              color: 'text.primary',
            },
            '& .announcementHeader:hover': {
              color: 'text.secondary',
            },
            backgroundColor: 'primary.main',
            '@media (max-width: 900px)': {
              textAlign: 'center',
            },
            '@media (min-width: 900px)': {},
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
          }}
        >
          <Typography variant="h4" sx={{ color: 'background.paper' }}>Office Posts and Announcements</Typography>
        </Paper>
        {/* <Typography variant="h4" sx={{ color: 'background.paper' }}>{selectedLink?.n}</Typography> */}

        <Box
          sx={{
            '@media (min-width: 1330px)': {},
            '@media (max-width: 1330px)': {},
          }}
        >
          {posts ? (
            <EnhancedTable
              columns={globalColumn}
              data={posts}
              // setData={setData}
              // updateMyData={updateMyData}
              skipPageReset={false}
            />
          ) : null}
        </Box>
      </Box>
    </>
  );
}
