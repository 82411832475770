import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import Box from '@mui/material/Box';
import { lazyLoad } from 'utils/loadable';
import { Divider, Paper, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import specialLinks from '../../../assets/specialLinks.json';
import { Post } from 'types/post';
import { Image } from 'mui-image';

export const LegislativeBio = lazyLoad(
  () => import('./LegislativeBio'),
  module => module.LegislativeBioContent,
);

export function LegislativeBioContent(props: any) {
  const { pathname } = useLocation();

  const [contentState, setContentState] = React.useState<any>(null);
  const [announcement, setAnnouncement] = React.useState<Post | undefined>(
    undefined,
  );

  const findLinkContentByLink = (link: string) => {
    const _data = specialLinks.find(item => item.uniqueLink === link);
    setContentState(_data?.postContent);
    return _data;
  };

  const onContentStateChange = contentState => {
    setContentState(contentState);
  };

  // React.useEffect(() => {
  //   console.log(pathname);
  //   setAnnouncement(findLinkContentByLink(pathname));
  //   //console.log(findAnnouncementByID(Number(params.announcementId)));
  // }, []);

  return (
    <>
      <Helmet>
        <title>Legislative Bio</title>
        <meta name="description" content="Legislative bio" />
      </Helmet>
      <Box sx={{ p: '15px' }}>
        <Paper
          variant="outlined"
          sx={{
            // mt: '7.5px',
            p: '12px',
            '& a': {
              textDecoration: 'none',
            },
            '& .announcementHeader': {
              color: 'text.primary',
            },
            '& .announcementHeader:hover': {
              color: 'text.secondary',
            },
            backgroundColor: 'primary.main',
            '@media (max-width: 900px)': {
              textAlign: 'center',
            },
            '@media (min-width: 900px)': {},
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
          }}
        >
          <Typography variant="h4" sx={{ color: 'background.paper' }}>
            Vice Mayor's Bio
          </Typography>
        </Paper>
        <Paper
          variant="outlined"
          sx={{
            my: '15px',
            p: '15px',
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
          }}
        >
          <Image
            src={require(`../../../assets/uploads/viceMayorBio.jpg`)}
            duration={0}
            fit="contain"
            height="40rem"
          />
          <Typography
            sx={{ textAlign: 'center', mb: '15px', mt: '7.5px' }}
            variant="h5"
          >
            HON. Lugen T. Ortilano
          </Typography>
          <Typography sx={{ textAlign: 'justify' }}>
            Hon. Lugen T. Ortilano lives in Brgy. Atabayan, Tigbauan, Iloilo. He
            was born on April 27, 1971, in Tigbauan, Iloilo. Presently, he is
            now 51 years old, a married man. He finished his elementary at
            Barroc Elementary School in 1984, his secondary at Oton National
            High School in 1988 and his college with the course, Bachelor of
            Science in Marine Transportation in Iloilo Maritime Academy in 1993.
          </Typography>
          <Typography sx={{ textAlign: 'justify' }}>
            He is a hardworking father where he worked for 9 years in Sea
            Service Odjfell Tankers and is a successful businessman since 2005.
            He is the President of the Abright Builders Corporation in Brgy.
            Atabayan, Tigbauan, Iloilo, where the business started in year 2018.
            He is also a Chief Managing Officer in FLS Enterprise which is also
            in Brgy. Atabayan, Tigbauan, Iloilo where it started in year 2005.
            Moreover, he is the owner of the Captain Dad’s Resto in Gaisano
            Capital Oton, Iloilo where it started in year 2010.
          </Typography>
          <Typography sx={{ textAlign: 'justify' }}>
            He was awarded as the Exceptional Courage of the US Coastguard in
            Marine Safety Office, Hampton Road USA on March 2004. Additionally,
            he was awarded as Excellence & Commitment & for His Extraordinary
            Courage in Magsaysay Maritime Corporation last September 23, 2004.
            Furthermore, he got an award for the Outstanding Seafarers of the
            year Valor Award in Quirino Grandstand, Rizal Park, Manila which was
            awarded by former President, Gloria Macapagal Arroyo, on September
            26, 2004. Also, he got another award from the former President,
            Gloria Macapagal Arroyo, which is The BagongBayani Foundation Inc.
            in Malacanang Palace, Manila, Philippines on December 2, 2005.
          </Typography>
          <Typography sx={{ textAlign: 'justify' }}>
            Hon. Lugen T. Ortilano believes in his one objective; To be in a
            position where I can apply my drive and commitment in the
            organization where I can prove my potential and achieve the target
            and the desired results and take the organization further ahead by
            utilizing my capabilities.
          </Typography>
        </Paper>
        <Paper
          variant="outlined"
          sx={{
            my: '15px',
            p: '15px',
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
          }}
        >
          <Typography sx={{ textAlign: 'justify' }}>
            The Sangguniang Bayan as the Legislative Body of the Municipality
            enacts Ordinances and Resolutions. It also appropriates funds for
            the general welfare of the municipality.
          </Typography>
        </Paper>
        <Paper
          variant="outlined"
          sx={{
            // mt: '7.5px',
            p: '12px',
            '& a': {
              textDecoration: 'none',
            },
            '& .announcementHeader': {
              color: 'text.primary',
            },
            '& .announcementHeader:hover': {
              color: 'text.secondary',
            },
            backgroundColor: 'primary.main',
            '@media (max-width: 900px)': {
              textAlign: 'center',
            },
            '@media (min-width: 900px)': {},
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
          }}
        >
          <Typography variant="h4" sx={{ color: 'background.paper' }}>
            Vice Mayor's Contact Information
          </Typography>
        </Paper>
        <Paper
          variant="outlined"
          sx={{
            my: '15px',
            p: '15px',
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
          }}
        >
          <Typography sx={{ textAlign: 'justify' }}>
            Office of the Municipal Vice Mayor
          </Typography>
          <Typography sx={{ textAlign: 'justify' }}>
            2nd Floor, Tigbauan Municipal Bldg., Tejero St., Brgy. 9, Tigbauan,
            Iloilo
          </Typography>
          <Typography sx={{ textAlign: 'justify' }}>511-8532</Typography>
        </Paper>
      </Box>
    </>
  );
}
