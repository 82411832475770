import { Button, SvgIcon, Menu, MenuItem, Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { ReactComponent as accessibility } from '../../../assets/icons/universal-access-solid.svg';
import { ReactComponent as fileAltRegular } from '../../../assets/icons/file-alt-regular.svg';
import { ReactComponent as lowVisionSolid } from '../../../assets/icons/low-vision-solid.svg';
import { ReactComponent as chevronDownSolid } from '../../../assets/icons/chevron-down-solid.svg';
import { ReactComponent as arrowAltCircleDown } from '../../../assets/icons/arrow-alt-circle-down-regular.svg';
import AccessibilityDialog from './AccessibilityDialog';
export const AccessibilityMenu = (props: any) => {
  const [anchor, setAnchor] = React.useState<null | HTMLElement>(null);

  const [dialogOpen, setDialogOpen] = React.useState<boolean>(false);
  const open = Boolean(anchor);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchor(event.currentTarget);
  };
  const handleClose = () => {
    setAnchor(null);
  };
  const triggerClose = (e: React.MouseEvent<HTMLElement>, cb: any) => {
    //@ts-ignore
    const className = e.relatedTarget.className;

    if (className !== undefined && typeof className === 'string') {
      if (
        className.includes('MuiList-root') ||
        className.includes('MuiMenuItem-root') ||
        className === ''
      ) {
      } else {
        //if (className.includes('btnNavPills')) if (cb) cb();
        if (cb) cb();
      }
    } else {
      if (cb) cb();
    }
  };
  return (
    <Box sx={{ height: '100%' }}>
      <AccessibilityDialog
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
      />

      <Button
        disableRipple
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        disableElevation
        disableTouchRipple
        disableFocusRipple
        onMouseEnter={e => setAnchor(e.currentTarget)}
        onMouseLeave={e => {
          e.stopPropagation();
          triggerClose(e, () => setAnchor(null));
        }}
        sx={{
          '& :hover': { backgroundColor: 'none' },
          height: '100%',
          width: '100%',
        }}
      >
        <SvgIcon
          sx={{
            color: 'black',
          }}
          inheritViewBox
          fontSize="large"
          component={accessibility}
        />
      </Button>

      <Menu
        // id="basic-menu"
        anchorEl={anchor}
        open={open}
        disableScrollLock
        disableRestoreFocus
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
          onMouseLeave: () => {
            setAnchor(null);
          },
        }}
        onClose={handleClose}
        sx={{
          pointerEvents: 'auto',
          transition: 'none !important',
          animation: 'none !important',
          borderRadius: '0px !important',
          '& .MuiPaper-root': {
            borderRadius: 0,

            boxShadow:
              'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
          },
          '& .MuiMenu-list': {
            padding: '4px 0',
          },
        }}
        // anchorOrigin={{
        //   vertical: 'bottom',
        //   horizontal: 'left',
        // }}
        // transformOrigin={{
        //   vertical: 'top',
        //   horizontal: 'left',
        // }}
      >
        <Tooltip title="Accessibility Statement">
          <MenuItem
            onClick={() => {
              setDialogOpen(true);
              handleClose();
            }}
          >
            <SvgIcon
              sx={{
                color: 'black',
              }}
              inheritViewBox
              fontSize="large"
              component={fileAltRegular}
            />
          </MenuItem>
        </Tooltip>
        {/* <Tooltip title="Toggle High Contrast">
          <MenuItem onClick={handleClose}>
            <SvgIcon
              sx={{
                color: 'black',
              }}
              inheritViewBox
              fontSize="large"
              component={lowVisionSolid}
            />
          </MenuItem>
        </Tooltip> */}
        <Tooltip title="Skip to Content">
          <MenuItem
            onClick={() => {
              props.scrollToContent();

              handleClose();
            }}
          >
            <SvgIcon
              sx={{
                color: 'black',
              }}
              inheritViewBox
              fontSize="large"
              component={arrowAltCircleDown}
            />
          </MenuItem>
        </Tooltip>
        <Tooltip title="Skip to Footer">
          <MenuItem
            onClick={() => {
              props.scrollToBottom();
              handleClose();
            }}
          >
            <SvgIcon
              sx={{
                color: 'black',
              }}
              inheritViewBox
              fontSize="large"
              component={chevronDownSolid}
            />
          </MenuItem>
        </Tooltip>
      </Menu>
    </Box>
  );
};
