import React from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Button, Menu, MenuItem, MenuProps } from '@mui/material';
import { styled } from '@mui/system';
import { ArrowDropDown } from '@mui/icons-material';

export const CustomDropdownLink = (
  menuArray: any,
  componentName: string,
  label: string,
  linkHref?: string,
  customComponent?: any,
  linkButtonHref?: string,
) => {
  const [anchor, setAnchor] = React.useState<null | HTMLElement>(null);
  const [active, setActive] = React.useState<Boolean>(false);
  const { pathname } = useLocation();

  React.useEffect(() => {
    triggerActive();
  }, [pathname]);
  const triggerActive = () => {
    if (pathname === '' || (pathname === '/' && linkHref === 'home')) {
      setActive(true);
    } else {
      setActive(false);

      if (pathname.includes('mayors-page') && linkHref === 'mayors-page')
        setActive(true);
      if (pathname.includes('legislative') && linkHref === 'legislative')
        setActive(true);
      if (pathname.includes('offices') && linkHref === 'offices')
        setActive(true);
      if (pathname.includes('tourism') && linkHref === 'tourism')
        setActive(true);
    }
  };

  const triggerClose = (e: React.MouseEvent<HTMLElement>, cb: any) => {
    //@ts-ignore
    const className = e.relatedTarget.className;
    e.preventDefault();
    e.stopPropagation();
    if (className !== undefined && typeof className === 'string') {
      if (
        className.includes('MuiList-root') ||
        className.includes('MuiMenuItem-root') ||
        className === ''
      ) {
      } else {
        //if (className.includes('btnNavPills')) if (cb) cb();
        if (cb) cb();
      }
    } else {
      if (cb) cb();
    }
  };

  return (
    <>
      <RouterLink
        style={{ textDecoration: 'none' }}
        onClick={e => {
          setAnchor(null);
        }}
        to={
          linkButtonHref
            ? linkButtonHref
              ? linkButtonHref
              : '/'
            : linkHref
            ? linkHref
            : '/'
        }
      >
        <Button
          id={`btnNav${componentName}Pill`}
          className="btnNavPills btnNavPilContainerFullHeight"
          aria-controls={
            Boolean(anchor) ? `menuButton${componentName}` : undefined
          }
          aria-haspopup="true"
          aria-expanded={Boolean(anchor) ? 'true' : undefined}
          variant="contained"
          //href={linkHref}

          sx={{
            backgroundColor: active ? '#EBEBEB !important' : 'none',
            borderRadius: 0,
          }}
          disableElevation
          disableRipple
          disableTouchRipple
          disableFocusRipple
          onClick={e => {
            setAnchor(e.currentTarget);
          }}
          onMouseEnter={e => {
            e.preventDefault();
            setAnchor(e.currentTarget);
          }}
          onMouseLeave={e => {
            e.preventDefault();
            triggerClose(e, () => setAnchor(null));
          }}
          endIcon={<ArrowDropDown sx={{ color: 'rgba(0,0,0,0.4)' }} />}
        >
          {label}
        </Button>
      </RouterLink>

      <StyledMenu
        id={`menuButton${componentName}`}
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
          variant: 'menu',

          onMouseLeave: () => {
            setAnchor(null);
          },
        }}
        anchorEl={anchor}
        open={Boolean(anchor)}
        onClose={() => setAnchor(null)}
      >
        {customComponent
          ? customComponent.map(val => val)
          : menuArray.map(val => (
              <MenuItem
                // sx={{ p: 0, m: 0 }}
                sx={{ '& a': { textDecoration: 'none' } }}
                onClick={() => setAnchor(null)}
                key={`${val.l}-${val.n}`}
                disableRipple
              >
                <RouterLink
                  onClick={() => setAnchor(null)}
                  style={{
                    width: '100%',
                    height: '100%',
                    padding: '5px',
                    color: 'black',
                    textAlign: 'left',
                  }}
                  to={`/${linkHref}/${val.l}`}
                >
                  {val.n}
                </RouterLink>
              </MenuItem>
            ))}
      </StyledMenu>
    </>
  );
};
const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    transitionDuration={0}
    disableScrollLock
    disableRestoreFocus
    // disableEnforceFocus
    //disableAutoFocus
    sx={{
      pointerEvents: 'auto',
      transition: 'none !important',
      animation: 'none !important',
      borderRadius: '0px !important',
      '& .MuiPaper-root': {
        borderRadius: 0,

        boxShadow:
          'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      },
      '& .MuiMenu-list': {
        padding: '4px 0',
      },
    }}
    // anchorOrigin={{
    //   vertical: 'bottom',
    //   horizontal: 'left',
    // }}
    // transformOrigin={{
    //   vertical: 'top',
    //   horizontal: 'left',
    // }}
    {...props}
  />
))(({ theme }) => ({}));
