import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import Box from '@mui/material/Box';
import { lazyLoad } from 'utils/loadable';
import { Divider, Paper, Typography } from '@mui/material';
import { Image } from 'mui-image';
export const MissionVision = lazyLoad(
  () => import('./MissionVision'),
  module => module.MissionVisionContent,
);

export function MissionVisionContent(props: any) {
  return (
    <>
      <Helmet>
        <title>Mission Vision</title>
        <meta name="description" content="Mission Vision" />
      </Helmet>
      <Paper
        variant="outlined"
        sx={{
          // mt: '7.5px',
          p: '12px',
          mb: 2,
          '& a': {
            textDecoration: 'none',
          },
          '& .announcementHeader': {
            color: 'text.primary',
          },
          '& .announcementHeader:hover': {
            color: 'text.secondary',
          },
          backgroundColor: 'primary.main',
          '@media (max-width: 900px)': {
            textAlign: 'center',
          },
          '@media (min-width: 900px)': {},
          boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
        }}
      >
        <Typography variant="h3" sx={{ color: 'background.paper' }}>
          Mission Vision
        </Typography>
      </Paper>
      <Box
        sx={{
          p: '15px',
          boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',

          backgroundColor: 'background.paper',
        }}
      >
        <Box sx={{ pt: '15px' }}>
          <Image
            src={require('../../../assets/uploads/vision.jpg')}
            duration={0}
            fit="cover"
            style={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px' }}
          />
        </Box>
        <Box sx={{ pt: '15px' }}>
          <Image
            src={require('../../../assets/uploads/mission.jpg')}
            duration={0}
            fit="cover"
            style={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px' }}
          />
        </Box>
      </Box>
    </>
  );
}
