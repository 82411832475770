import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import Box from '@mui/material/Box';
import { lazyLoad } from 'utils/loadable';
import { Divider, Typography, Paper } from '@mui/material';
import { Image } from 'mui-image';
export const MunicipalDisasterRiskReductionOffice = lazyLoad(
  () => import('./MunicipalDisasterRiskReductionOffice'),
  module => module.MunicipalDisasterRiskReductionOfficeContent,
);

export function MunicipalDisasterRiskReductionOfficeContent(props: any) {
  return (
    <>
      <Helmet>
        <title>Municipal Disaster Risk Reduction Management Office</title>
        <meta
          name="description"
          content="Municipal Disaster Risk Reduction Management Office"
        />
      </Helmet>
      <Box sx={{ p: '15px' }}>
        <Paper
          variant="outlined"
          sx={{
            // mt: '7.5px',
            p: '12px',
            '& a': {
              textDecoration: 'none',
            },
            '& .announcementHeader': {
              color: 'text.primary',
            },
            '& .announcementHeader:hover': {
              color: 'text.secondary',
            },
            backgroundColor: 'primary.main',
            '@media (max-width: 900px)': {
              textAlign: 'center',
            },
            '@media (min-width: 900px)': {},
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
          }}
        >
          <Typography variant="h4" sx={{ color: 'background.paper' }}>
            Municipal Disaster Risk Reduction Management Office
          </Typography>
        </Paper>
        <Paper
          variant="outlined"
          sx={{
            my: '15px',
            p: '15px',
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
          }}
        >
          <Typography sx={{ textAlign: 'justify' }}>
            The Municipal Disaster Risk Reduction and Management Office function
            as the manager of all disaster, calamity and emergency operations
            within the municipality. It also conduct training, seminars,
            workshops and orientations to the members of the community as part
            of the preparedness for disaster risk reduction and management.
          </Typography>
        </Paper>
        {/* {!props.noHeaderImg ? (
          <Paper variant="outlined" sx={{ my: '15px', p: '15px' }}>
            <Image
              src={require(`../../../assets/localOffice/${props.specialLink}.jpg`)}
              duration={0}
              fit="contain"
              height="40rem"
            />
          </Paper>
        ) : null} */}
        <Paper
          variant="outlined"
          sx={{
            // mt: '7.5px',
            p: '12px',
            '& a': {
              textDecoration: 'none',
            },
            '& .announcementHeader': {
              color: 'text.primary',
            },
            '& .announcementHeader:hover': {
              color: 'text.secondary',
            },
            backgroundColor: 'primary.main',
            '@media (max-width: 900px)': {
              textAlign: 'center',
            },
            '@media (min-width: 900px)': {},
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
          }}
        >
          <Typography variant="h4" sx={{ color: 'background.paper' }}>MDRRMO Contact Information</Typography>
        </Paper>
        <Paper
          variant="outlined"
          sx={{
            my: '15px',
            p: '15px',
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
          }}
        >
          <Typography sx={{ textAlign: 'justify' }}>
            Municipal Disaster Risk Reduction and Management Office (MDRRMO)
          </Typography>
          <Typography sx={{ textAlign: 'justify' }}>
            Timulco Building, Tejero St., Brgy. 9, Tigbauan, Iloilo
          </Typography>
          <Typography sx={{ textAlign: 'justify' }}>321-1528</Typography>
        </Paper>
      </Box>
    </>
  );
}
