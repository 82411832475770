import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Collapse, List, ListItemButton, ListItemText } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
export const CustomCollapseList = (
  menuArray: any,
  componentName: string,
  label: string,
  linkHref?: string,
  customComponent?: any,
) => {
  const [anchor, setAnchor] = React.useState<null | HTMLElement | Boolean>(
    null,
  );

  return (
    <>
      <ListItemButton
        onClick={e => {
          e.stopPropagation();
          setAnchor(!anchor);
        }}
      >
        <ListItemText primary={label} />
        {anchor ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={Boolean(anchor)} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {customComponent
            ? customComponent.map(
                val => val,

                // <ListItemButton
                //   sx={{ pl: 5 }}
                //   onClick={() => setAnchor(null)}
                //   disableRipple
                // >
                //   <ListItemText primary={val} />
                // </ListItemButton>
              )
            : menuArray.map(val => (
                <ListItemButton
                  sx={{ pl: 5 }}
                  onClick={() => setAnchor(null)}
                  key={`${val.l}-${val.n}`}
                  disableRipple
                >
                  <RouterLink
                    style={{
                      width: '100%',
                      height: '100%',
                      padding: '5px',
                      color: 'black',
                      textAlign: 'left',
                      textDecoration: 'none',
                    }}
                    to={`../${linkHref}/${val.l}`}
                  >
                    {val.n}
                  </RouterLink>
                </ListItemButton>
              ))}
        </List>
      </Collapse>
    </>
  );
};
