import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import Box from '@mui/material/Box';
import { lazyLoad } from 'utils/loadable';
import {
  AppBar,
  Button,
  Dialog,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Slide,
  Toolbar,
  Typography,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { CloseRounded } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { GlobalReducer } from 'types/globalReducer';
import { uiToggleDocViewer } from 'actions/actionCreator';
// import DocViewer from 'react-doc-viewer';
import { Image } from 'mui-image';
export const GlobalDocumentViewer = lazyLoad(
  () => import('./globalDocumentViewer'),
  module => module.GlobalDocumentViewerContent,
);
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export function GlobalDocumentViewerContent(props: any) {
  // const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const isOpen: boolean = useSelector(
    (state: GlobalReducer) => state.globalDocViewer.isOpen,
  );
  const fileName: any = useSelector(
    (state: GlobalReducer) => state.globalDocViewer.fileName,
  );
  const uniqueID: any = useSelector(
    (state: GlobalReducer) => state.globalDocViewer.uniqueID,
  );

  const handleClose = () => {
    dispatch(uiToggleDocViewer(false));
  };

  return (
    <Dialog
      // fullScreen
      open={isOpen}
      onClose={handleClose}
      maxWidth="md"
      fullWidth

      // TransitionComponent={Transition}
    >
      <Box>
        <Image
          src={`${
            process.env.REACT_APP_API_URL
          }global/getFile?uniqueId=${uniqueID}&filename=${encodeURIComponent(
            fileName,
          )}&token=${localStorage.getItem('LUT')}`}
          duration={0}
          fit="contain"
          // height="300px"
        />
        {/* <DocViewer
          documents={[
            {
              uri: `${
                process.env.REACT_APP_API_URL
              }global/getFile?uniqueId=${uniqueID}&filename=${encodeURIComponent(
                fileName,
              )}&token=${localStorage.getItem('LUT')}`,
            },
          ]}
        /> */}
      </Box>
    </Dialog>
  );
}
