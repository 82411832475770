import { IVisitorData } from 'types/globalReducer';
import { Post } from 'types/post';

type requestType = 'get' | 'post' | 'put' | 'delete';

export function apiRequest(
  type: requestType,
  data: any,
  loadingMsg: string,
  url: string,
  resolve: any,
  reject: any,
) {
  return {
    type: 'API_REQUEST',
    reqType: type,
    loadingMsg: loadingMsg,
    data,
    payload: url,
    resolve: resolve,
    reject: reject,
  };
}
export function uiToggleDocViewer(
  toggle: boolean,
  fileName?: string,
  uniqueID?: string,
) {
  return {
    type: 'UI_TOGGLE_DOC_VIEWER_WDATA',
    payload: { data: toggle, fileName: fileName, uniqueID: uniqueID },
  };
}
export function apiStoreNav(nav: string) {
  return {
    type: 'API_STORE_NAV',
    payload: { data: nav },
  };
}
export function apiStoreAnnouncements(announcements: Post) {
  return {
    type: 'API_STORE_ANNOUNCEMENTS',
    payload: { data: announcements },
  };
}
export function apiStoreExecutiveOrders(executiveOrders: Post) {
  return {
    type: 'API_STORE_EXECUTIVE_ORDERS',
    payload: { data: executiveOrders },
  };
}
export function apiStoreResolutions(resolutions: Post) {
  return {
    type: 'API_STORE_RESOLUTIONS',
    payload: { data: resolutions },
  };
}
export function apiStoreOrdinances(ordinances: Post) {
  return {
    type: 'API_STORE_ORDINANCES',
    payload: { data: ordinances },
  };
}
export function apiStorePosts(posts: Post) {
  return {
    type: 'API_STORE_POSTS',
    payload: { data: posts },
  };
}
export function apiStoreLoggedUser(user: any) {
  return {
    type: 'API_STORE_LOGGED_USER',
    payload: { user: user },
  };
}
export function apiSetAuthenticated(isAuthenticated: any) {
  return {
    type: 'API_SET_AUTHENTICATED',
    payload: { isAuthenticated: isAuthenticated },
  };
}
export function apiSetSocketInstance(socket: any) {
  return {
    type: 'SET_SOCKET_INSTANCE',
    payload: socket,
  };
}
export function apiSetSocketID(socketId: any) {
  return {
    type: 'SET_SOCKET_ID',
    payload: socketId,
  };
}
export function apiSetVisitorData(visitorData: IVisitorData) {
  return {
    type: 'API_STORE_VISITOR_DATA',
    payload: visitorData,
  };
}

export function apiSetVisitorInteractionsId(visitorInteractions: Number[]) {
  return {
    type: 'API_STORE_VISITOR_POST_INTERACTIONS',
    payload: visitorInteractions,
  };
}
