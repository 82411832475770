import { apiRequest, apiSetVisitorData } from 'actions/actionCreator';
import { Filter } from 'types/filter';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { IVisitorData } from 'types/globalReducer';
import { Dispatch } from '@reduxjs/toolkit';
const fpPromise = FingerprintJS.load();
class ContentAPI {
  getVisitorId = async (dispatch: Dispatch<any>, cb?: any) => {
    const fp = await fpPromise;
    const result = await fp.get();
    dispatch(apiSetVisitorData({ uniqueKey: result.visitorId, Ip: '' }));
    if (cb) await cb();
    return { uniqueKey: result.visitorId, Ip: '' };
  };
  likePost = async (
    dispatch: Dispatch<any>,
    postId: string,
    uniqueKey: string,
    cb?: VoidFunction,
  ) => {
    const data = await new Promise((resolve, reject) => {
      dispatch(
        apiRequest(
          'post',
          { id: postId, uniqueKey },
          'Liking Post',
          'posts/likePost',
          resolve,
          reject,
        ),
      );
    }).then(
      async (onfulfilled: any) => {
        return onfulfilled.data;
      },
      (onrejected: any) => {
        //(onrejected);
        return onrejected.data;
        //this.handleDropCancel();
      },
    );
    if (cb) await cb();
    return data;
  };
  getPosts = async (dispatch: any, filter: Filter, cb?: any) => {
    let filterData: string[] = [];
    if (filter.isAnnouncement !== undefined)
      filterData.push(`&isAnnouncement=${filter.isAnnouncement}`);
    if (filter.isArchived !== undefined)
      filterData.push(`&isArchived=${filter.isArchived}`);
    if (filter.uniqueLink !== undefined)
      filterData.push(`&uniqueLink=${filter.uniqueLink}`);
    if (filter.postType !== undefined)
      filterData.push(`&postType=${filter.postType}`);
    if (filter.limit !== undefined) filterData.push(`&limit=${filter.limit}`);

    const data = await new Promise((resolve, reject) => {
      dispatch(
        apiRequest(
          'get',
          {},
          'Fetching Posts from server',
          `posts/getPosts/?id=0${filterData.join('')}`,
          resolve,
          reject,
        ),
      );
    }).then(
      async (onfulfilled: any) => {
        return onfulfilled.data;
      },
      (onrejected: any) => {
        //(onrejected);
        return [];
        //this.handleDropCancel();
      },
    );
    if (cb) await cb();
    return data;
  };
  getAllPostForList = async (dispatch: any, filter: Filter, cb?: any) => {
    let filterData: string[] = [];
    if (filter.isAnnouncement !== undefined)
      filterData.push(`&isAnnouncement=${filter.isAnnouncement}`);
    if (filter.isArchived !== undefined)
      filterData.push(`&isArchived=${filter.isArchived}`);
    if (filter.uniqueLink !== undefined)
      filterData.push(`&uniqueLink=${filter.uniqueLink}`);
    if (filter.postType !== undefined)
      filterData.push(`&postType=${filter.postType}`);
    if (filter.limit !== undefined) filterData.push(`&limit=${filter.limit}`);

    const data = await new Promise((resolve, reject) => {
      dispatch(
        apiRequest(
          'get',
          {},
          'Fetching Posts from server',
          `posts/getAllPostForList/?id=0${filterData.join('')}`,
          resolve,
          reject,
        ),
      );
    }).then(
      async (onfulfilled: any) => {
        return onfulfilled.data;
      },
      (onrejected: any) => {
        //(onrejected);
        return [];
        //this.handleDropCancel();
      },
    );
    if (cb) await cb();
    return data;
  };
  searchPosts = async (dispatch: any, srchStr: string, cb?: any) => {
    const data = await new Promise((resolve, reject) => {
      dispatch(
        apiRequest(
          'get',
          null,
          'Fetching Posts from server',
          `posts/searchPosts?srchStr=${srchStr}`,
          resolve,
          reject,
        ),
      );
    }).then(
      async (onfulfilled: any) => {
        return onfulfilled.data;
      },
      (onrejected: any) => {
        //(onrejected);
        return [];
        //this.handleDropCancel();
      },
    );
    if (cb) await cb();
    return data;
  };
  getAllPosts = async (dispatch: any, cb?: any) => {
    const data = await new Promise((resolve, reject) => {
      dispatch(
        apiRequest(
          'get',
          null,
          'Fetching Posts from server',
          'posts/getPosts',
          resolve,
          reject,
        ),
      );
    }).then(
      async (onfulfilled: any) => {
        return onfulfilled.data;
      },
      (onrejected: any) => {
        //(onrejected);
        return [];
        //this.handleDropCancel();
      },
    );
    if (cb) await cb();
    return data;
  };
  getAllAnnouncements = async (dispatch: any, cb?: any) => {
    const data = await new Promise((resolve, reject) => {
      dispatch(
        apiRequest(
          'get',
          null,
          'Fetching Announcements from server',
          'posts/getAnnouncements',
          resolve,
          reject,
        ),
      );
    })
      .then(
        async (onfulfilled: any) => {
          return onfulfilled.data;
        },
        (onrejected: any) => {
          //(onrejected);
          return [];
          //this.handleDropCancel();
        },
      )
      .finally(() => {
        if (cb) cb();
      });

    return data;
  };
  getPostById = async (
    dispatch: any,
    id: string,
    uniqueKey: string,
    cb?: any,
  ) => {
    const data = await new Promise((resolve, reject) => {
      dispatch(
        apiRequest(
          'get',
          id,
          'Fetching Post Content',
          `posts/getPost?id=${Number(id)}&uniqueKey=${uniqueKey}`,
          resolve,
          reject,
        ),
      );
    }).then(
      async (onfulfilled: any) => {
        return onfulfilled.data;
      },
      (onrejected: any) => {
        //(onrejected);
        return onrejected.data;
        //this.handleDropCancel();
      },
    );
    if (cb) await cb();
    return data;
  };
  getPostByUniqueLink = async (dispatch: any, link: string, cb?: any) => {
    const data = await new Promise((resolve, reject) => {
      dispatch(
        apiRequest(
          'get',
          link,
          'Fetching Post Content',
          `posts/getPostByUniqueLink?uniqueLink=${link}`,
          resolve,
          reject,
        ),
      );
    }).then(
      async (onfulfilled: any) => {
        return onfulfilled.data;
      },
      (onrejected: any) => {
        //(onrejected);
        return onrejected.data;
        //this.handleDropCancel();
      },
    );
    if (cb) await cb();
    return data;
  };
  getVisitorInteractions = async (
    dispatch: any,
    uniqueKey: string,
    cb?: any,
  ) => {
    const data = await new Promise((resolve, reject) => {
      dispatch(
        apiRequest(
          'get',
          null,
          'Fetching Visitor Interactions',
          `posts/getVisitorInteractions?uniqueKey=${uniqueKey}`,
          resolve,
          reject,
        ),
      );
    }).then(
      async (onfulfilled: any) => {
        return onfulfilled.data;
      },
      (onrejected: any) => {
        //(onrejected);
        return onrejected.data;
        //this.handleDropCancel();
      },
    );
    if (cb) await cb();
    return data;
  };
}
export default new ContentAPI();
