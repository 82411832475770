/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { Routes, Route, BrowserRouter, useLocation } from 'react-router-dom';
import { GlobalStyle } from 'styles/global-styles';

import { HomePage } from './pages/HomePage/Loadable';
import { NotFoundPage } from './components/NotFoundPage/Loadable';
import Box from '@mui/material/Box';
//import Divider from '@mui/material/Divider';
import { Grid } from '@mui/material';

import { Image } from 'mui-image';
import { MayorsBio } from './pages/MayorsPage/MayorsBio';
import { MayorsServices } from './pages/MayorsPage/MayorsServices';
import { MayorsAnnouncements } from './pages/MayorsPage/MayorsAnnouncements';
import { MayorsActivities } from './pages/MayorsPage/MayorsActivities';
import { MayorsOrganizationalChart } from './pages/MayorsPage/MayorsOrganizationalChart';
import { LegislativeBio } from './pages/LegislativePage/LegislativeBio';
import { LegislativeAnnouncements } from './pages/LegislativePage/LegislativeAnnouncements';
import { LegislativeOrganizationalChart } from './pages/LegislativePage/LegislativeOrganizationalChart';
import { TigbauanFestival } from './pages/TourismPage/TigbauanFestival';
import { TigbauanProducts } from './pages/TourismPage/TigbauanProducts';
import { TouristSpots } from './pages/TourismPage/TouristSpots';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { ContactUs } from './pages/ContactUs';
import { MissionVision } from './pages/HomePage/MissionVision';
import { AboutTigbauan } from './pages/HomePage/AboutTigbauan';
import { PostPage } from './pages/PostPage/PostPage';

import { PostsPage } from './pages/PostPage/PostsPage';

import 'react-h5-audio-player/lib/styles.css';
import { About } from './pages/About';
import useWindowDimensions from './components/useWindowDimensions';
import { SearchPage } from './pages/SearchPage';

import RouterBreadcrumbs from './pages/indexComponents/BreadCrumbs';
import { SideNavRight } from './pages/indexComponents/SideNavRight';
import { Tourism } from './pages/TourismPage/Tourism';
import { AccessibilityHotkeys } from './pages/indexComponents/AccessibilityHotkeys';
import { BannerCarousel } from './pages/indexComponents/BannerCarousel';
import { Offices } from './pages/Offices';
import contentAPI from 'api/contentAPI';
import { useDispatch, useSelector } from 'react-redux';
import {
  apiSetVisitorData,
  apiSetVisitorInteractionsId,
  apiStoreAnnouncements,
  apiStoreExecutiveOrders,
  apiStoreOrdinances,
  apiStorePosts,
  apiStoreResolutions,
} from 'actions/actionCreator';

import { SideNav } from './pages/indexComponents/SideNav';
import { StandardFooter } from './pages/indexComponents/StandardFooter';
import { SideNavMobile } from './pages/indexComponents/SideNavMobile';
import { AuxMenu } from './pages/indexComponents/AuxMenu';
import { ResponsiveAppBar } from './pages/indexComponents/ResponsiveAppBar';
import { MastHead } from './pages/indexComponents/MastHead';

import { FooterVideoCarousel } from './pages/indexComponents/FooterVideoCarousel';
import { GlobalReducer } from 'types/globalReducer';
import { TlinkNames } from 'types/links';
import { FooterSocialIframe } from './pages/indexComponents/FooterSocialIFrame';
import {
  TourismOffice,
  TourismOfficeContent,
} from './pages/LocalOfficePage/TourismOffice';
import FingerprintJS from '@fingerprintjs/fingerprintjs';

export function App() {
  const { width } = useWindowDimensions();

  const dispatch = useDispatch();
  const [triggerOnce, setTriggerOnce] = React.useState<boolean>(false);
  //const { i18n } = useTranslation();
  const selectedNav: TlinkNames | string = useSelector(
    (state: GlobalReducer) => state.navData.selectedNav,
  );

  const fpPromise = FingerprintJS.load();

  const fetchPosts = React.useCallback(async () => {
    // setIsPostLoading(true);

    let _posts = await contentAPI.getAllPostForList(dispatch, {
      //limit: 10,
      //isAnnouncement: 0,
    });

    dispatch(apiStorePosts(_posts));
  }, []);
  const fetchAnnouncements = React.useCallback(async () => {
    //setIsAnnouncementLoading(true);
    let _announcements = await contentAPI.getPosts(dispatch, {
      limit: 10,
      isAnnouncement: 1,
    });

    dispatch(apiStoreAnnouncements(_announcements));
  }, []);

  const fetchExecutiveOrder = React.useCallback(async () => {
    //setIsAnnouncementLoading(true);
    let _executiveOrder = await contentAPI.getPosts(dispatch, {
      limit: 5,
      postType: 'executive-order',
    });
    //console.log(_executiveOrder);
    dispatch(apiStoreExecutiveOrders(_executiveOrder));
  }, []);
  const fetchResolutions = React.useCallback(async () => {
    //setIsAnnouncementLoading(true);
    let _resolutions = await contentAPI.getPosts(dispatch, {
      limit: 5,
      postType: 'resolutions',
    });
    //console.log(_resolutions);
    dispatch(apiStoreResolutions(_resolutions));
  }, []);
  const fetchOrdinances = React.useCallback(async () => {
    //setIsAnnouncementLoading(true);
    let _ordinances = await contentAPI.getPosts(dispatch, {
      limit: 5,
      postType: 'ordinances',
    });
    //console.log(_ordinances);
    dispatch(apiStoreOrdinances(_ordinances));
  }, []);

  const fetchVisitorInteractions = React.useCallback(async uniqueKey => {
    //setIsAnnouncementLoading(true);
    let _userInteractions = await contentAPI.getVisitorInteractions(
      dispatch,
      uniqueKey,
    );
    //console.log(_userInteractions);
    dispatch(apiSetVisitorInteractionsId(_userInteractions));
  }, []);

  React.useEffect(() => {
    const fetchVisitorId = async () => {
      // Get the visitor identifier when you need it.
      const fp = await fpPromise;
      const result = await fp.get();
      //console.log(result.visitorId);
      dispatch(apiSetVisitorData({ uniqueKey: result.visitorId, Ip: '' }));
      fetchVisitorInteractions(result.visitorId);
    };
    // const fetchVisitorInformation = async () => {
    //   const response = await fetch('https://geolocation-db.com/json/');
    //   const data = await response.json();
    //   console.log(data);
    // };

    fetchPosts();
    //fetchAnnouncements();
    //fetchExecutiveOrder();
    //fetchResolutions();
    //fetchOrdinances();
    //fetchVisitorId();
    //fetchVisitorInformation();
  }, []);

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      left: 0,
      behavior: 'smooth',
    });
  };
  const scrollToContent = () => {
    const LOC = window.location.href;
    const element = document.getElementById('gridContent');
    //console.log(LOC, element);
    if (
      LOC === 'http://localhost:3500/' ||
      LOC === 'https://www.tigbauan.gov.ph/'
    ) {
      //console.log('trigger top');
      window.scrollTo(0, 0);
    } else {
      if (element) {
        const y = element.getBoundingClientRect().top + window.scrollY;
        window.scrollTo({ top: y, behavior: 'smooth' });
      }
    }
  };
  React.useEffect(() => {
    setTriggerOnce(triggerOnce ? true : false);
    scrollToContent();
  }, [selectedNav, triggerOnce]);

  return (
    <BrowserRouter>
      <Helmet
        titleTemplate="%s | Municipality of Tigbauan"
        defaultTitle="Municipality of Tigbauan"
        //htmlAttributes={{ lang: i18n.language }}
      >
        <meta name="description" content="Tigbauan Official Website" />
      </Helmet>
      <AccessibilityHotkeys />
      <ResponsiveAppBar
        scrollToBottom={scrollToBottom}
        scrollToContent={scrollToContent}
      />

      <Box
        sx={{
          height: '100vh',
          maxWidth: '100vw !important',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <MastHead />
        <BannerCarousel />
        <AuxMenu />
        {width <= 900 ? null : <HeaderBackgroundContainer />}

        <Box
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              mt: 2,
              backgroundColor: 'transparent',
              '@media (min-width: 1330px)': {
                maxWidth: '80rem',
              },
            }}
          >
            <RouterBreadcrumbs />
            {/* {width <= 900 ? null : (
              <>
                <Image
                  src={require(`../assets/oneTigbauan.jpg`)}
                  duration={0}
                  fit="contain"
                  height="290px"
                />
              </>
            )} */}

            {/* {width <= 900 ? (
              <Box sx={{ p: 0 }}>
                <FooterVideoCarousel />

              </Box>
            ) : (

              <Box
                sx={{
                  boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                  backgroundColor: 'background.paper',
                  p: 2,
                }}
              >
                <FooterVideoCarousel />
              </Box>
            )} */}
            {/* {width <= 900 ? null : (
              <Box sx={{ py: '15px', backgroundColor: 'transparent' }}>
                <Image
                  src={require(`../assets/oneTigbauan.jpg`)}
                  duration={0}
                  fit="contain"
                  height="290px"
                />
              </Box>
            )} */}

            <Box
              id="gridContent"
              sx={{ position: 'absolute', mt: '-60px' }}
            ></Box>
            <Grid
              sx={{
                py: '15px',
                pb: 5,
                '@media (max-width: 900px)': {
                  // px: '7.5px',
                },
              }}
              container
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={2.5}
                sx={{
                  '@media (min-width: 900px)': {
                    pr: '7.5px',
                  },
                  '@media (max-width: 900px)': {
                    px: '7.5px',
                  },
                }}
              >
                {width <= 900 ? (
                  <></> // <SideNavMobile width={width} />
                ) : (
                  <SideNav width={width} />
                )}
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={7}
                sx={{
                  // maxHeight: '120vh',
                  // overflow: 'scroll',
                  '@media (min-width: 900px)': {
                    px: '7.5px',
                  },
                }}
              >
                <Routes>
                  <Route
                    path="/"
                    element={<HomePage scrollToContent={scrollToContent} />}
                  />

                  <Route
                    path="/home"
                    element={<HomePage scrollToContent={scrollToContent} />}
                  />

                  <Route
                    path="home/about-tigbauan"
                    element={
                      <AboutTigbauan scrollToContent={scrollToContent} />
                    }
                  />
                  <Route
                    path="home/mission-vision"
                    element={
                      <MissionVision scrollToContent={scrollToContent} />
                    }
                  />
                  <Route
                    path="mayors-page/office-order"
                    element={<PostsPage />}
                  />
                  <Route
                    path="mayors-page/executive-order"
                    element={<PostsPage />}
                  />
                  <Route
                    path="mayors-page/services"
                    element={
                      <MayorsServices scrollToContent={scrollToContent} />
                    }
                  />
                  <Route
                    path="mayors-page/announcements"
                    element={
                      <MayorsAnnouncements scrollToContent={scrollToContent} />
                    }
                  />
                  <Route
                    path="mayors-page/activities"
                    element={
                      <MayorsActivities scrollToContent={scrollToContent} />
                    }
                  />
                  <Route
                    path="mayors-page/organizational-chart"
                    element={
                      <MayorsOrganizationalChart
                        scrollToContent={scrollToContent}
                      />
                    }
                  />
                  <Route
                    path="mayors-page"
                    element={<MayorsBio scrollToContent={scrollToContent} />}
                  />
                  <Route
                    path="mayors-page/mayors-bio"
                    element={<MayorsBio scrollToContent={scrollToContent} />}
                  />

                  <Route
                    path="legislative"
                    element={
                      <LegislativeBio scrollToContent={scrollToContent} />
                    }
                  />
                  <Route
                    path="legislative/vice-mayors-bio"
                    element={
                      <LegislativeBio scrollToContent={scrollToContent} />
                    }
                  />
                  <Route path="legislative/services" element={<PostsPage />} />
                  <Route
                    path="legislative/ordinances"
                    element={<PostsPage />}
                  />
                  <Route
                    path="legislative/resolutions"
                    element={<PostsPage />}
                  />
                  <Route
                    path="legislative/announcements"
                    element={
                      <LegislativeAnnouncements
                        scrollToContent={scrollToContent}
                      />
                    }
                  />
                  <Route
                    path="legislative/organizational-chart"
                    element={
                      <LegislativeOrganizationalChart
                        scrollToContent={scrollToContent}
                      />
                    }
                  />

                  <Route
                    path="local-offices"
                    element={<HomePage scrollToContent={scrollToContent} />}
                  />

                  <Route
                    path="local-offices/accounting-office"
                    element={<PostsPage scrollToContent={scrollToContent} />}
                  />
                  <Route
                    path="local-offices/agriculture-office"
                    element={<PostsPage scrollToContent={scrollToContent} />}
                  />
                  <Route
                    path="local-offices/assessors-office"
                    element={<PostsPage scrollToContent={scrollToContent} />}
                  />
                  <Route
                    path="local-offices/budget-office"
                    element={<PostsPage scrollToContent={scrollToContent} />}
                  />
                  <Route
                    path="local-offices/engineering-office"
                    element={<PostsPage scrollToContent={scrollToContent} />}
                  />
                  <Route
                    path="local-offices/environmental-and-natural-resources-office"
                    element={<PostsPage scrollToContent={scrollToContent} />}
                  />
                  <Route
                    path="local-offices/health-office"
                    element={<PostsPage scrollToContent={scrollToContent} />}
                  />
                  <Route
                    path="local-offices/local-civil-registrar-office"
                    element={<PostsPage scrollToContent={scrollToContent} />}
                  />
                  <Route
                    path="local-offices/market-and-slaughter-house"
                    element={<PostsPage scrollToContent={scrollToContent} />}
                  />
                  <Route
                    path="local-offices/municipal-disaster-risk-reduction-management-office"
                    element={<PostsPage scrollToContent={scrollToContent} />}
                  />
                  <Route
                    path="local-offices/municipal-information-unit"
                    element={<PostsPage scrollToContent={scrollToContent} />}
                  />
                  <Route
                    path="local-offices/planning-office"
                    element={<PostsPage scrollToContent={scrollToContent} />}
                  />
                  <Route
                    path="local-offices/social-welfare-and-development-office"
                    element={<PostsPage scrollToContent={scrollToContent} />}
                  />
                  <Route
                    path="local-offices/treasurers-office"
                    element={<PostsPage scrollToContent={scrollToContent} />}
                  />
                  <Route
                    path="local-offices/tourism-office"
                    element={<PostsPage scrollToContent={scrollToContent} />}
                  />
                  <Route
                    path="local-offices/rural-health-unit"
                    element={<PostsPage scrollToContent={scrollToContent} />}
                  />
                  <Route
                    path="local-offices/office-of-the-municipal-mayor"
                    element={<PostsPage scrollToContent={scrollToContent} />}
                  />
                  <Route
                    path="local-offices/sangguniang-bayan-office"
                    element={<PostsPage scrollToContent={scrollToContent} />}
                  />
                  <Route
                    path="local-offices/human-resource-office"
                    element={<PostsPage scrollToContent={scrollToContent} />}
                  />
                  <Route
                    path="local-offices/tourism-office"
                    element={<PostsPage scrollToContent={scrollToContent} />}
                  />
                  <Route
                    path="local-offices/public-employment-service-office"
                    element={<PostsPage scrollToContent={scrollToContent} />}
                  />
                  <Route
                    path="local-offices/general-services-office"
                    element={<PostsPage scrollToContent={scrollToContent} />}
                  />
                  <Route
                    path="local-offices/internal-audit-service"
                    element={<PostsPage scrollToContent={scrollToContent} />}
                  />
                  <Route
                    path="local-offices/office-of-the-municipal-administrator"
                    element={<PostsPage scrollToContent={scrollToContent} />}
                  />
                  <Route
                    path="national-offices"
                    element={<HomePage scrollToContent={scrollToContent} />}
                  />
                  <Route
                    path="national-offices/bureau-of-fire-protection"
                    element={<PostsPage scrollToContent={scrollToContent} />}
                  />
                  <Route
                    path="national-offices/comelec"
                    element={<PostsPage scrollToContent={scrollToContent} />}
                  />
                  <Route
                    path="national-offices/department-of-agrarian-reform"
                    element={<PostsPage scrollToContent={scrollToContent} />}
                  />
                  <Route
                    path="national-offices/department-of-interior-and-local-government"
                    element={<PostsPage scrollToContent={scrollToContent} />}
                  />
                  <Route
                    path="national-offices/population-commmission"
                    element={<PostsPage scrollToContent={scrollToContent} />}
                  />
                  <Route
                    path="national-offices/philippine-national-police"
                    element={<PostsPage scrollToContent={scrollToContent} />}
                  />
                  <Route
                    path="national-offices/post-office"
                    element={<PostsPage scrollToContent={scrollToContent} />}
                  />
                  <Route
                    path="national-offices/philippine-coast-guard"
                    element={<PostsPage scrollToContent={scrollToContent} />}
                  />

                  {/* <Route path="tourism" element={HomePage} /> */}
                  <Route
                    path="tourism/"
                    element={<Tourism scrollToContent={scrollToContent} />}
                  />
                  <Route
                    path="tourism/tigbauan-festival"
                    element={
                      <TigbauanFestival scrollToContent={scrollToContent} />
                    }
                  />
                  <Route
                    path="tourism/tigbauan-products"
                    element={
                      <TigbauanProducts scrollToContent={scrollToContent} />
                    }
                  />
                  <Route
                    path="tourism/tourists-spots"
                    element={<TouristSpots scrollToContent={scrollToContent} />}
                  />

                  <Route
                    path="/bids-and-awards"
                    element={<PostsPage scrollToContent={scrollToContent} />}
                  />

                  <Route
                    path="/contact-us"
                    element={<ContactUs scrollToContent={scrollToContent} />}
                  />
                  <Route
                    path="/posts"
                    element={<PostsPage scrollToContent={scrollToContent} />}
                  />
                  <Route
                    path="posts/:postId"
                    element={<PostPage scrollToContent={scrollToContent} />}
                  />
                  <Route
                    path="/announcements"
                    element={<PostsPage scrollToContent={scrollToContent} />}
                  />
                  <Route
                    path="announcements/:postId"
                    element={<PostPage scrollToContent={scrollToContent} />}
                  />
                  {/* <Route path="/announcements" element={<AnnouncementsPage />} />
      <Route
        path="announcements/:announcementId"
        element={<AnnouncementPage />}
      /> */}
                  <Route
                    path="/about"
                    element={<About scrollToContent={scrollToContent} />}
                  />
                  <Route
                    path="/search/:srchStr"
                    element={<SearchPage scrollToContent={scrollToContent} />}
                  />
                  <Route
                    path="/downloads"
                    element={<PostsPage scrollToContent={scrollToContent} />}
                  />
                  <Route
                    path="/jobs"
                    element={<PostsPage scrollToContent={scrollToContent} />}
                  />
                  <Route
                    path="/offices"
                    element={<Offices scrollToContent={scrollToContent} />}
                  />
                  <Route
                    path="/transparency-seal"
                    element={<PostsPage scrollToContent={scrollToContent} />}
                  />

                  <Route path="*" element={<NotFoundPage />} />
                </Routes>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={2.5}
                sx={{
                  '@media (min-width: 900px)': {
                    pl: '7.5px',
                  },
                  '@media (max-width: 900px)': {
                    px: '7.5px',
                  },
                }}
              >
                <SideNavRight />
              </Grid>
            </Grid>
            {width <= 900 ? (
              <Box sx={{ p: 1 }}>
                <SideNavMobile width={width} />
              </Box>
            ) : null}
          </Box>
        </Box>

        <Box>
          <FooterContainer />
        </Box>

        <StandardFooter />
        <GlobalStyle />
      </Box>
    </BrowserRouter>
  );
}
const HeaderBackgroundContainer = () => {
  const { pathname } = useLocation();
  return (
    <>
      {/* {pathname === '/' && ( */}
      <>
        <Box
          sx={{
            position: 'absolute',
            height: '1200px',
            width: '100%',
            zIndex: -2,
            '& .background': {
              position: 'absolute',
              // opacity: '0.2',
              // zIndex: -1000,

              backgroundImage: `url(${require('../assets/municipalFacade.png')})`,
              backgroundAttachment: 'fixed',
              backgroundPosition: 'center -250px',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            },
          }}
        >
          <Box
            className="background"
            height={'1200px'}
            sx={{
              position: 'absolute',
              width: '100%',
              // clipPath: 'polygon(0 0, 100% 0, 100% 50%, 50% 100%, 0 50%)',
              opacity: 0.3,
              zIndex: -2,
            }}
            // wrapperClassName="background"
            // height={'80%'}
            // src={require('../assets/tourism/dreamersValley/1.jpg')}
          />
        </Box>
        <Box
          sx={{
            position: 'absolute',
            backgroundColor: 'rgb(255,255,255)',
            background:
              'linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)',
            height: '100px',
            mt: '1100px',
            width: '100%',
            zIndex: -2,
          }}
        ></Box>
        {/* <Box
            sx={{
              backgroundColor: '#FFE58A',
              position: 'absolute',
              zIndex: -6,
              height: '900px',
              width: '100%',
              clipPath: 'polygon(50% 50%, 100% 25%, 100% 100%, 0 100%, 0 25%)',
            }}
          ></Box> */}
      </>
      {/* )} */}
    </>
  );
};
const FooterContainer = () => {
  const { pathname } = useLocation();
  return (
    <>
      {/* {pathname === '/' && ( */}
      <>
        <Box
          sx={{
            position: 'absolute',
            height: '300px',
            width: '100%',
            zIndex: -2,
            marginTop: '-400px',
            '& .background': {
              // position: 'absolute',
              // opacity: '0.2',
              // zIndex: -1000,

              backgroundImage: `url(${require('../assets/municipalFacade.png')})`,
              backgroundAttachment: 'fixed',
              backgroundPosition: 'center -50px ',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            },
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              backgroundColor: 'rgb(255,255,255)',

              background:
                'linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)',
              height: '100px',
              width: '100%',
              zIndex: 1,
            }}
          ></Box>
          <Box
            className="background"
            height={'400px'}
            sx={{
              position: 'absolute',
              width: '100%',
              // clipPath: 'polygon(0 0, 100% 0, 100% 50%, 50% 100%, 0 50%)',
              opacity: 0.3,
              zIndex: -5,
            }}
            // wrapperClassName="background"
            // height={'80%'}
            // src={require('../assets/tourism/dreamersValley/1.jpg')}
          />
        </Box>
      </>
    </>
  );
};
