export type TlinkNamesView =
  | 'About Tigbauan'
  | 'Mission Vision'
  | 'Home'
  | 'Mayors Page'
  | 'Mayors Bio'
  | 'Office Order'
  | 'Executive Order'
  | 'Services'
  | 'Announcements'
  | 'Activities'
  | 'Organizational Chart'
  | 'Legislative'
  | 'Vice Mayors Bio'
  | 'Services'
  | 'Ordinances'
  | 'Resolutions'
  | 'Announcements'
  | 'Organizational Chart'
  | 'Local Offices'
  | 'Accounting Office'
  | 'Agriculture Office'
  | 'Assessors Office'
  | 'Budget Office'
  | 'Engineering Office'
  | 'Environment And Natural Resources Office'
  | 'Health Office'
  | 'Local Civil Registrar Office'
  | 'Market And Slaughter House'
  | 'Municipal Disaster Risk Reduction Office'
  | 'Planning Office'
  | 'Social Welfare And Development Office'
  | 'Treasurers Office'
  | 'National Offices'
  | 'Bureau of Fire Protection'
  | 'Comelec'
  | 'Department of Agrarian Reform'
  | 'Department of Interior and Local Government'
  | 'Population Commmission'
  | 'Philippine National Police'
  | 'Post Offices'
  | 'Tourism'
  | 'Tourism Office'
  | 'Tigbauan Festival'
  | 'Tigbauan Products'
  | 'Tourists Spots'
  | 'Bids and Awards'
  | 'Contact Us'
  | 'Posts'
  | 'Jobs'
  | 'Announcements'
  | 'Post'
  | 'Announcement'
  | 'About'
  | 'Search'
  | 'Downloads'
  | 'Transparency Seal';
export type TlinkNames =
  | 'mayors-page'
  | 'legislative'
  | 'executive-order'
  | 'office-order'
  | 'activities'
  | 'accounting-office'
  | 'agriculture-office'
  | 'assessors-office'
  | 'budget-office'
  | 'engineering-office'
  | 'environmental-and-natural-resources-office'
  | 'health-office'
  | 'local-civil-registrar-office'
  | 'market-and-slaughter-house'
  | 'municipal-disaster-risk-reduction-management-office'
  | 'planning-office'
  | 'social-welfare-and-development-office'
  | 'treasurers-office'
  | 'bureau-of-fire-protection'
  | 'comelec'
  | 'department-of-agrarian-reform'
  | 'department-of-interior-and-local-government'
  | 'population-commmission'
  | 'philippine-national-police'
  | 'post-office'
  | 'tigbauan-festival'
  | 'tigbauan-products'
  | 'tourists-spots'
  | 'bids-and-awards'
  | 'jobs'
  | 'downloads'
  | 'transparency-seal'
  | 'ordinances'
  | 'resolutions'
  | 'tourism-office';
export const specialPages = [
  { n: 'Downloads', l: 'downloads', componentName: 'Downloads' },
  {
    n: 'Transparency Seal',
    l: 'transparency-seal',
    componentName: 'TransparencySeal',
  },
  { n: 'Jobs', l: 'jobs', componentName: 'Jobs' },
  { n: 'Bids and Awards', l: 'bids-and-awards', componentName: 'BidsAwards' },
  { n: 'Resolutions', l: 'resolutions', componentName: 'Resolutions' },
  { n: 'Ordinances', l: 'ordinances', componentName: 'Ordinances' },
  {
    n: 'Executive Order',
    l: 'executive-order',
    componentName: 'ExecutiveOrder',
  },
  { n: 'Office Order', l: 'office-order', componentName: 'OfficeOrder' },
  {
    n: 'Accounting Office',
    l: 'accounting-office',
    componentName: 'AccountingOffice',
  },
  {
    n: 'Agriculture Office',
    l: 'agriculture-office',
    componentName: 'AgricultureOffice',
  },
  {
    n: 'Assessors Office',
    l: 'assessors-office',
    componentName: 'AssessorsOffice',
  },
  { n: 'Budget Office', l: 'budget-office', componentName: 'BudgetOffice' },
  {
    n: 'Engineering Office',
    l: 'engineering-office',
    componentName: 'EngineeringOffice',
  },
  {
    n: 'Environmental & Natural Resources Office',
    l: 'environmental-and-natural-resources-office',
    componentName: 'DENR',
  },
  { n: 'Health Office', l: 'health-office', componentName: 'HealthOffice' },
  {
    n: 'Local Civil Registrar Office',
    l: 'local-civil-registrar-office',
    componentName: 'LocalCivilRegistrarOffice',
  },
  {
    n: 'Market and Slaughter House',
    l: 'market-and-slaughter-house',
    componentName: 'MarketSlaughterHouse',
  },
  {
    n: 'Municipal Disaster Risk Reduction Management Office',
    l: 'municipal-disaster-risk-reduction-management-office',
    componentName: 'MDRRMO',
  },
  {
    n: 'Planning Office',
    l: 'planning-office',
    componentName: 'PlanningOffice',
  },
  {
    n: 'Social Welfare & Development Office',
    l: 'social-welfare-and-development-office',
    componentName: 'SocialWelfareDevelopmentOffice',
  },
  {
    n: 'Treasurers Office',
    l: 'treasurers-office',
    componentName: 'TreasurersOffice',
  },
  {
    n: 'Bureau of Fire Protection',
    l: 'bureau-of-fire-protection',
    componentName: 'BFP',
  },
  { n: 'Comelec', l: 'comelec', componentName: 'Comelec' },
  {
    n: 'Department of Agrarian Reform',
    l: 'department-of-agrarian-reform',
    componentName: 'DAR',
  },
  {
    n: 'Department of Interior and Local Government',
    l: 'department-of-interior-and-local-government',
    componentName: 'DILG',
  },
  {
    n: 'Population Commmission',
    l: 'population-commmission',
    componentName: 'POPCOM',
  },
  {
    n: 'Philippine National Police',
    l: 'philippine-national-police',
    componentName: 'PNP',
  },
  { n: 'Post Office', l: 'post-office', componentName: 'PostOffice' },
  { n: 'Tigbauan Festival', l: 'tigbauan-festival', componentName: 'Festival' },
  { n: 'Tigbauan Products', l: 'tigbauan-products', componentName: 'Products' },
  { n: 'Tourists Spots', l: 'tourists-spots', componentName: 'TouristSpots' },
  { n: 'Mayors Office', l: 'mayors-office', componentName: 'MayorsOffice' },
  {
    n: 'Legislative Office',
    l: 'legislative-office',
    componentName: 'Legislative',
  },
  { n: 'Posts', l: 'posts', componentName: 'Posts' },
  { n: 'Announcements', l: 'announcements', componentName: 'Announcements' },
  { n: 'Search', l: 'search', componentName: 'Search' },
  { n: 'General', l: 'general', componentName: 'General' },
  {
    n: 'Municipal Information Unit',
    l: 'municipal-information-unit',
    componentName: 'MunicipalInformationUnit',
  },
  {
    n: 'Rural Health Unit',
    l: 'rural-health-unit',
    componentName: 'RuralHealthUnit',
  },
  {
    n: 'Office of the Municipal Mayor',
    l: 'office-of-the-municipal-mayor',
    componentName: 'OfficeOfTheMunicipalMayor',
  },
  {
    n: 'Sangguniang Bayan Office',
    l: 'sangguniang-bayan-office',
    componentName: 'SangguniangBayanOffice',
  },
  {
    n: 'Human Resource Office',
    l: 'human-resource-office',
    componentName: 'HumanResourceOffice',
  },
  {
    n: 'Tourism Office',
    l: 'tourism-office',
    componentName: 'TourismOffice',
  },
  {
    n: 'Public Employment Service Office',
    l: 'public-employment-service-office',
    componentName: 'PublicEmploymentServiceOffice',
  },
  {
    n: 'General Services Office',
    l: 'general-services-office',
    componentName: 'GeneralServicesOffice',
  },
  {
    n: 'Internal Audit Service',
    l: 'internal-audit-service',
    componentName: 'InternalAuditService',
  },
  {
    n: 'Office of the Municipal Administrator',
    l: 'office-of-the-municipal-administrator',
    componentName: 'OfficeOfTheMunicipalAdministrator',
  },
  {
    n: 'Philippine Coast Guard',
    l: 'philippine-coast-guard',
    componentName: 'PhilippineCoastGuard',
  },

];
