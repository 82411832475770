import React, { useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useNavigate } from 'react-router-dom';

export const AccessibilityHotkeys = () => {
  let navigate = useNavigate();
  useHotkeys('alt+h', () => {
    navigate('/');
  });
  useHotkeys('alt+c', () => {
    navigate('/contact-us');
  });
  useHotkeys('alt+s', () => {
    navigate('/search');
  });
  useHotkeys('alt+m', () => {
    navigate('/sitemap');
  });
  useHotkeys('alt+r', () => {
    navigate('/');
  });
  useHotkeys('alt+q', () => {
    navigate('/about');
  });

  return <></>;
};
