import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Button, Grid } from '@mui/material';
import { Box } from '@mui/system';

export const AuxMenu = () => {
  return (
    <Box
      sx={{
        maxHeight: '58px',
        height: '58px',
        px: '0px !important',
        backgroundColor: '#f7f7f7',
        zIndex: 4,
        display: 'flex',
        justifyContent: 'center',
        boxShadow: 'rgb(0 0 0 / 20%) 0px 2px 4px 0px',
      }}
    >
      <Box sx={{ width: '80rem' }}>
        <Grid
          sx={{
            '& .btnNavPills': {
              fontSize: '0.8rem',
              m: 0,
              // px: 0.5,
              py: 0,
              whiteSpace: 'nowrap',
              textTransform: 'none',
              backgroundColor: 'transparent',
              height: '100%',
              width: '100%',
              //zIndex: 2001,
              color: 'black',
              '@media (max-width: 1330px)': {
                display: 'none',
                // width: '80rem',
              },
            },

            '& .btnNavPills:hover': {
              backgroundColor: '#e4e4e4',
              borderRadius: '0px',
            },
            '& .btnNavPillContainer': {
              textAlign: 'center',
              backgroundColor: 'transparent',
              height: '58px',
              pointerEvents: 'auto',

              //zIndex: 2000,
            },
            '& .btnNavPilContainerFullHeight': { height: '58px' },
          }}
          container
        >
          <Grid className="btnNavPillContainer" item xs={2}>
            <RouterLink
              style={{ textDecoration: 'none' }}
              to={`/bids-and-awards`}
            >
              <Button
                id="btnNavBidsAndAwardsPill"
                className="btnNavPills btnNavPilContainerFullHeight"
                variant="contained"
                disableElevation
                onClick={() => {}}
              >
                Bids and Awards
              </Button>
            </RouterLink>
          </Grid>
          <Grid className="btnNavPillContainer" item xs={2}>
            <Button
              id="btnNaDisclosurePolicyPill"
              className="btnNavPills btnNavPilContainerFullHeight"
              variant="contained"
              disableElevation
              href="https://fdpp.dilg.gov.ph/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Full Disclosure Policy
            </Button>
          </Grid>
          <Grid className="btnNavPillContainer" item xs={2}>
            <RouterLink style={{ textDecoration: 'none' }} to={`/contact-us`}>
              <Button
                id="btnNaDisclosurePolicyPill"
                className="btnNavPills btnNavPilContainerFullHeight"
                variant="contained"
                disableElevation
              >
                Contact Us
              </Button>
            </RouterLink>
          </Grid>
          <Grid className="btnNavPillContainer" item xs={2}>
            <RouterLink style={{ textDecoration: 'none' }} to={`/home/about-tigbauan`}>
              <Button
                id="btnNaDisclosurePolicyPill"
                className="btnNavPills btnNavPilContainerFullHeight"
                variant="contained"
                disableElevation
              >
                About
              </Button>
            </RouterLink>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
